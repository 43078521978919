import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.scss';

const loader = (
	<div className="preloader-wrapper small active">
		<div className="spinner-layer">
			<div className="circle-clipper left">
				<div className="circle"></div>
			</div>
			<div className="gap-patch">
				<div className="circle"></div>
			</div>
			<div className="circle-clipper right">
				<div className="circle"></div>
			</div>
		</div>
	</div>
);

const Button = props => {
	const {
		id,
		className,
		children,
		variant,
		block,
		type,
		hasLink,
		disabled,
		onClick,
		loading,
		onChange
	} = props;

	return (
		<button
			className={classNames('button', variant, className, {
				'has-link': hasLink,
				block: block
			})}
			type={type}
			id={id}
			disabled={disabled}
			onClick={onClick}
			onChange={onChange}
		>
			{loading && loader}
			{children}
		</button>
	);
};

Button.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	variant: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	hasLink: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	loading: PropTypes.bool,
	onChange: PropTypes.func
};

Button.defaultProps = {
	type: 'button',
	hasLink: false,
	disabled: false,
	loading: false
};

export default Button;
