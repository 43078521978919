import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import classNames from 'classnames';
import M from 'materialize-css';
import axiosAuth from '../../../../utils/axiosAuth';
import roundNumber from '../../../../utils/roundNumber';
import SideModal from '../../../common/side-modal/SideModal';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Button from '../../../common/button/Button';
import Icon from '../../../common/airbag-icons/Icon';
import PhoneInput from '../../../common/phone-input/PhoneInput';
import Manager from '../manager/Manager';
import './Driver.scss';

function Driver(props) {
  const { company, driverId } = props;
  const managerId = null;
  const [driver, setDriver] = useState({});
  const [lastNotifications, setLastNotifications] = useState([]);
  const [firstNotification, setFirstNotification] = useState(null);
  const [managerOpen, setManagerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [diffCoins, setDiffCoins] = useState();
  const [userStatus, setUserStatus] = useState(true);
  const [currentCoins, setCurrentCoins] = useState(0);
  const [phone, setPhone] = useState('');
  // Save which section is render
  const [section, setSection] = useState('info');
  // Save coins Adjustments history
  const [coinsAdjustments, setCoinsAdjustments] = useState(null);
  // Open modal to modify coins
  const [updateCoinsModal, setUpdateCoinsModal] = useState(false);
  // Description to modify points
  const [description, setDescription] = useState('');
  // Reason to modify Points
  const [reason, setReason] = useState(null);

  useEffect(() => {
    if (section === 'info') {
      // Get driver info
      fetchUser();
    } else {
      // Get coin adjustment info
      fetchCoinAdjustment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  // Fetch coins-adjustments and admin name
  function fetchCoinAdjustment() {
    setLoading(true);
    axiosAuth
      .get(`/api/users/coins-adjustments`, {
        params: { companyId: company.id, driverId }
      })
      .then((res) => {
        // Get coinsAdjustments and admin's name
        setCoinsAdjustments(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert('Tuvimos un error, intenta de nuevo más tarde.');
      });
  }

  function downloadHistoryCoins() {
    setLoading(true);
    axiosAuth
      .get(`/api/users/download-history-coins`, {
        params: { companyId: company.id, driverId }
      })
      .then((res) => {
        const { toBase64, fileName } = res.data;
        setLoading(false);
        const csvContent = atob(toBase64);
        const blob = new Blob([csvContent], {
          type: 'data:application/octet-stream;base64'
        });

        const url = window.URL.createObjectURL(blob);
        FileSaver.saveAs(url, fileName);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert('Tuvimos un error, intenta de nuevo más tarde.');
      });
  }

  // Render coins Adjustments history
  function renderCoinsTable() {
    // Loader en lo que cargan los conductores
    if (!coinsAdjustments)
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );

    // Si no hay conductores
    if (!coinsAdjustments.length)
      return (
        <div id="no-data">
          <p>Sin movimientos en puntos</p>
        </div>
      );

    // Coin Adjustments history table
    return (
      <div className="airbag-table-container" id="drivers-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span className="pointer">
                  Fecha y <br></br> hora
                </span>
              </th>
              <th>
                <span className="pointer">
                  Diferencia <br></br> de puntos
                </span>
              </th>
              <th>
                <span className="pointer">
                  Puntos <br></br> iniciales
                </span>
              </th>
              <th>
                <span className="pointer">
                  Puntos <br></br> finales
                </span>
              </th>
              <th>Motivo</th>
              <th>Administrador</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            {coinsAdjustments.map((coinsAdjustments, i) => (
              <tr key={coinsAdjustments.id}>
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  {dayjs(coinsAdjustments.created).format('DD/MM HH:mm')}
                </td>
                <td>
                  {coinsAdjustments.action === 'add' ? '+' : null}
                  {roundNumber(coinsAdjustments.diff, 2)}
                </td>
                <td>{roundNumber(coinsAdjustments.oldCoins, 2)}</td>
                <td>{roundNumber(coinsAdjustments.newCoins, 2)}</td>
                <td>
                  {coinsAdjustments.reason ? coinsAdjustments.reason : ''}
                </td>
                <td>{coinsAdjustments.admin.fullName}</td>
                <td>{coinsAdjustments.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // Render a modal to adjust coins and re-fetch history table
  function renderCoinsModal() {
    if (updateCoinsModal) {
      return (
        <>
          <div className="closeModalContainer right-align">
            <FontAwesomeIcon
              className="icon"
              id="close-modal-button"
              icon="times"
              onClick={() => {
                setUpdateCoinsModal(false);
              }}
            />
          </div>
          <div className="row">
            <div className="col s12 m6">
              <label htmlFor="coins">Ingresa cantidad a sumar/restar</label>
              <input
                className="fake-input"
                name="coins"
                type="number"
                placeholder="-2, +5"
                value={diffCoins}
                onChange={(e) => setDiffCoins(e.target.value)}
              />
            </div>
            <div className="col s12 m6">
              <label htmlFor="motive">Motivo</label>

              <select
                name="motive"
                id="motive"
                onChange={(e) => setReason(e.target.value)}
              >
                <option value="">Seleccione una opcion</option>
                <option value="prizes">Premios </option>
                <option value="tech-issue">Problema técnico</option>
                <option value="challenges">Retos</option>
                <option value="referral">Referidos</option>
                <option value="welcome-bonus">Bono de bienvenida</option>
                <option value="company-movement">Movimiento de empresa</option>
              </select>
            </div>
            <div className="col s12">
              <div>
                <label htmlFor="motive">Descripcion</label>
                <textarea
                  id="desc-area"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col s12 right-align">
              <Button
                variant="yellow-button"
                loading={loading}
                disabled={loading}
                type="button"
                onClick={() => {
                  if (!loading) {
                    updateCoins();
                    fetchCoinAdjustment();
                  }
                }}
              >
                Actualizar Puntos
              </Button>
            </div>
          </div>
        </>
      );
    }
  }

  // Coins adjustments tab
  function renderCoinsTab() {
    return (
      <>
        <div className="row _driver_">
          <div className="col s12">
            <label>Total de puntos</label>
            <p className="fake-input">{currentCoins}</p>
          </div>
          <div className="col s12 ">
            <div className="col s12 m6">
              <Button
                variant="yellow-button"
                id={'downloadButton'}
                loading={loading}
                disabled={loading}
                type="button"
                onClick={() => {
                  if (!loading) {
                    downloadHistoryCoins();
                  }
                }}
              >
                Descargar historial
              </Button>
            </div>
            {!updateCoinsModal && (
              <div className="col s12 m6">
                <Button
                  variant="yellow-button"
                  type="button"
                  onClick={() => {
                    setUpdateCoinsModal(true);
                  }}
                >
                  Modificar Puntos
                </Button>
              </div>
            )}
          </div>
          <div className="col s12">{renderCoinsModal()}</div>
          <div className="col s12">{renderCoinsTable()}</div>
        </div>
      </>
    );
  }

  // Fetch all user information
  function fetchUser() {
    axiosAuth
      .get(`/api/users`, { params: { companyId: company.id, driverId } })
      .then((res) => {
        const { user, lastNotifications, firstNotification } = res.data;
        setDriver(user);
        setLastNotifications(lastNotifications);
        if (firstNotification) setFirstNotification(firstNotification.date);
        if (user.coins) {
          setCurrentCoins(roundNumber(user.coins, 2));
        }
        if (user.status) setUserStatus(user.status === 'active' ? true : false);
        if (user.phone) setPhone(user.phone);
      })
      .catch((err) => {
        console.log(err);
        alert('Tuvimos un error, intenta de nuevo más tarde.');
      });
  }

  // Render the corresponding section
  function renderSection() {
    if (section === 'info') {
      return renderInfo();
    } else if (section === 'coins') {
      return renderCoinsTab();
    } else {
      return null;
    }
  }

  // Render all user info
  function renderInfo() {
    return (
      <div className="_driver_">
        <div className="row">
          <div className="col s12">
            <label>Conductor Deshabilitado/Habilitado</label>
            {renderSliderStatus()}
          </div>
          <div className="col s12">
            <label>Conductor creado</label>
            <p className="fake-input">
              {dayjs(driver.created).format('DD MMMM, YYYY HH:mm')}
            </p>
          </div>
          <div className="col s12 m6">
            <label>Primer ingreso</label>
            <p className="fake-input">
              {driver.firstLoginDate
                ? dayjs(driver.firstLoginDate).format('DD MMMM, YYYY HH:mm')
                : 'N/A'}
            </p>
          </div>
          <div className="col s12 m6">
            <label>Ingreso más reciente</label>
            <p className="fake-input">
              {driver.lastLogin
                ? dayjs(driver.lastLogin).format('DD MMMM, YYYY HH:mm')
                : 'N/A'}
            </p>
          </div>
          <div className="col s12 m6">
            <label>Primera activación</label>
            <p className="fake-input">
              {firstNotification
                ? dayjs(firstNotification).format('DD MMMM, YYYY HH:mm')
                : 'N/A'}
            </p>
          </div>
          <div className="col s12 m6">
            <label>Activación más reciente</label>
            <p className="fake-input">{getLastActivation(lastNotifications)}</p>
          </div>
          <div className="col s12">
            <label>Damoov ID</label>
            <p className="fake-input">
              {driver.raxelDeviceToken
                ? driver.raxelDeviceToken
                : 'Sin configurar'}
            </p>
          </div>
        </div>
        {driver.realTimeTrackingEnabled && (
          <React.Fragment>
            <div className="row big-label">
              <div className="col s12">
                <p>Realtime Tracking</p>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <Link
                  to={`/empresa/${company.id}/conductor/${driver.id}/realtime`}
                >
                  Ver mapa
                </Link>
              </div>
            </div>
          </React.Fragment>
        )}
        {/* {lastNotifications.length ? (
          <React.Fragment>
            <div className="row big-label">
              <div className="col s12">
                <p>Últimos cambios de estado</p>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <table className="notif">
                  <tbody>
                    {lastNotifications.map((notif, idx) => (
                      <tr key={idx}>
                        <td>
                          {notif.appStatus === 'active' ? (
                            <span className="green-t">Activación</span>
                          ) : (
                            <span className="red-t">Desactivación</span>
                          )}
                        </td>
                        <td>
                          {dayjs(notif.date).format('DD MMMM, YYYY HH:mm')}
                        </td>
                        <td>{renderNotifSource(notif)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </React.Fragment>
        ) : null} */}
        <div className="row big-label">
          <div className="col s12">
            <p>Información general</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6">
            <label>Email</label>
            <p className="fake-input">{driver.email}</p>
          </div>
          {driver.phoneModel && (
            <div className="col s12 m6">
              <label>Modelo</label>
              <p className="fake-input">{driver.phoneModel}</p>
            </div>
          )}
          {driver.appInfo && driver.appInfo.versionApp && (
            <div className="col s12 m6">
              <label>Versión de app</label>
              <p className="fake-input">{`${driver.appInfo.versionApp} (${driver.appInfo.versionCode})`}</p>
            </div>
          )}
          <div className="col s12 m6">
            <label>Puntos</label>
            <p className="fake-input">
              {driver.coins ? roundNumber(driver.coins, 2) : 0}
            </p>
          </div>
          <div className="row big-label">
            <div className="col s12">
              <p>Editar</p>
            </div>
          </div>
          <div className="col s12 m6">
            <PhoneInput phone={phone} setPhone={setPhone} label="Celular *" />
          </div>
          <div className="col s12 right-align">
            <Button
              variant="yellow-button"
              loading={loading}
              disabled={loading}
              type="button"
              onClick={() => {
                if (!loading) updatePhone();
              }}
            >
              Actualizar teléfono
            </Button>
          </div>
        </div>

        {/* Manager information */}
        <SideModal isOpen={managerOpen} setIsOpen={setManagerOpen}>
          {managerOpen && <Manager company={company} managerId={managerId} />}
        </SideModal>
      </div>
    );
  }

  function changeDriverStatus() {
    setLoading(true);
    const newStatus = !userStatus ? 'active' : 'inactive';
    M.toast({
      html: `Cambiando estado, espera unos segundos`,
      classes: 'info-toast'
    });
    // Make request
    axiosAuth
      .post('/api/users/update-status', {
        userId: driverId,
        status: newStatus,
        companyId: company.id
      })
      .then((res) => {
        setUserStatus(!userStatus);
        M.toast({
          html: `Conductor ${
            newStatus === 'active' ? 'habilitado' : 'deshabilitado'
          } exitosamente`,
          classes: 'success-toast'
        });
        fetchUser();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  // Function to update coins
  function updateCoins() {
    setLoading(true);

    // Make request
    axiosAuth
      .post(`/api/users/update-coins`, {
        companyId: company.id,
        driverId,
        coins: diffCoins,
        reason,
        description
      })
      .then((res) => {
        setLoading(false);
        setUpdateCoinsModal(false);
        setDiffCoins();
        setReason(null);
        setDriver({ ...driver, coins: diffCoins });
        setCurrentCoins(parseFloat(diffCoins) + parseFloat(currentCoins));
        fetchCoinAdjustment();
        M.toast({
          html: 'Puntos guardados exitosamente',
          classes: 'success-toast'
        });
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  // Function to update phone
  function updatePhone() {
    setLoading(true);

    // Make request
    axiosAuth
      .post('/api/users/update-phone', {
        userId: driverId,
        phone,
        companyId: company.id
      })
      .then((res) => {
        setLoading(false);
        setDriver({ ...driver, phone });
        M.toast({
          html: 'Telefono guardado exitosamente',
          classes: 'success-toast'
        });
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  // Getting last activation day
  function getLastActivation(arr) {
    // Find last activation
    for (const notification of arr) {
      if (notification.appStatus === 'active') {
        return dayjs(notification.date).format('DD MMMM, YYYY HH:mm');
      }
    }
    return 'N/A';
  }

  function renderSliderStatus() {
    return (
      <div id="slider-container">
        <input
          type="checkbox"
          id="switch"
          onChange={(e) => {
            changeDriverStatus();
          }}
          checked={userStatus}
        />
        <label htmlFor="switch">Toggle</label>
      </div>
    );
  }

  // function renderNotifSource(notif) {
  //   if (notif.createdBy) {
  //     return (
  //       <span
  //         className="notif-source admin-link"
  //         onClick={() => {
  //           setManagerId(notif.createdBy);
  //           setManagerOpen(true);
  //         }}
  //       >
  //         <FontAwesomeIcon icon="user-tie" /> Ver admin
  //       </span>
  //     );
  //   } else if (notif.source === 'self') {
  //     return (
  //       <span className="notif-source">
  //         <FontAwesomeIcon icon="user" /> Autoactivación
  //       </span>
  //     );
  //   } else {
  //     return (
  //       <span className="notif-source">
  //         <FontAwesomeIcon icon="clock" /> Horario laboral
  //       </span>
  //     );
  //   }
  // }

  if (!driver || !Object.keys(driver).length) return <AirbagLoader />;

  return (
    <div className="_driver_">
      <div>
        <div className="col s12" id="name">
          <h5>{driver.fullName}</h5>
          <span
            id="firestore-id"
            onClick={() => {
              var copySpan = document.getElementById('copy-driver-id-span');
              copySpan.select();

              try {
                var copyResult = document.execCommand('copy');
                if (copyResult)
                  M.toast({
                    html: 'ID copiado exitosamente',
                    classes: 'success-toast'
                  });
                else console.log('Oops, unable to copy');
              } catch (err) {
                console.log('Oops, unable to copy');
              }
            }}
          >
            <input id="copy-driver-id-span" defaultValue={driver.id} />
          </span>
          <p id="type">Operador</p>
        </div>
      </div>
      <div id="driver-menu">
        <span
          onClick={() => {
            setSection('info');
          }}
          className={classNames('sec', { active: section === 'info' })}
        >
          <span className="sec-content">
            <Icon icon="profile" className="sect-icon" />
            Información
          </span>
        </span>
        <span
          onClick={() => {
            setSection('coins');
          }}
          className={classNames('sec', {
            active: section === 'coins'
          })}
        >
          <span className="sec-content">
            <Icon icon="ranking2" className="sect-icon" />
            Puntos
          </span>
        </span>
      </div>
      {renderSection()}
    </div>
  );
}

export default Driver;
