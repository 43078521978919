import React, { useState } from 'react';
import Button from '../../common/button/Button';

const OnboardingModal = ({ isOpen, onConfirm, onboardingType }) => {
  // const [stage, setStage] = useState('');
  const stage = '';
  const [activationMode, setActivationMode] = useState('SINGLE_MODE');
  const [flags, setFlags] = useState({
    showMobileScores: true,
    showMobileTrips: true,
    showMobileWorkDay: false,
    isResting: false
  });
  const [file, setFile] = useState(null);

  const handleFlagChange = (flagKey) => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      [flagKey]: !prevFlags[flagKey]
    }));
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile?.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvContent = event.target.result;
        const firstLine = csvContent.split('\n')[0];
        const secondLine = csvContent.split('\n')[1];
        const headers = firstLine.split(',').map((header) => header.trim());

        const requiredHeaders = [
          'Nombre(s) del Operador',
          'Apellido(s) del Operador',
          'Telefono del Operador',
          'Grupo',
          'Puntos'
        ];

        const missingHeaders = requiredHeaders.filter(
          (required) => !headers.includes(required)
        );

        if (!secondLine) {
          alert(
            'El archivo CSV debe tener información valida en todas las columnas'
          );
          setFile(null);
        } else if (
          missingHeaders.length > 0 ||
          csvContent.split('\n').length < 2
        ) {
          alert(
            `Faltan las siguientes cabeceras requeridas: ${missingHeaders.join(
              ', '
            )}. Además, el archivo debe tener al menos dos filas`
          );
          setFile(null);
        } else {
          setFile(selectedFile);
        }
      };
      reader.readAsText(selectedFile);
    } else {
      alert('Por favor seleccione un archivo CSV válido y no vacío');
      setFile(null);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile?.type === 'text/csv') {
      setFile(droppedFile);
    } else {
      alert('Por favor arrastre un archivo CSV válido');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleConfirm = async () => {
    if (!file) {
      alert('Por favor seleccione un archivo antes de continuar');
      return;
    }
    onConfirm?.({ stage, activationMode, flags, file });
  };

  if (!isOpen) return null;

  return (
    <div id="_onboarding-modal_">
      <h5 id="main-t">Nuevo onboarding</h5>
      <div>
        <div>
          {onboardingType === 'conductores' && (
            <div className="row">
              {/* <div className="input-field col s12">
                <label className="active" htmlFor="stage">
                  Etapa:
                </label>
                <input
                  name="stage"
                  type="text"
                  value={stage}
                  onChange={(e) => setStage(e.target.value)}
                  placeholder="Ingresa la etapa"
                />
              </div> */}
              <div className="input-field col s12">
                <select
                  name="activationMode"
                  value={activationMode}
                  onChange={(e) => setActivationMode(e.target.value)}
                >
                  <option value="SINGLE_MODE">Habilitado</option>
                  <option value="ADMIN_MODE">Deshabilitado</option>
                </select>
                <label className="active" htmlFor="activationMode">
                  Activación manual
                </label>
              </div>
              <div className="col s12">
                <p>Configuración / Banderas:</p>
                <div>
                  {Object.entries(flags).map(([key, value]) => {
                    const flagLabels = {
                      showMobileScores: 'Mostrar calificaciones',
                      showMobileTrips: 'Mostrar viajes realizados',
                      showMobileWorkDay: 'Activar jornada laboral',
                      isResting: 'En descanso'
                    };

                    return (
                      <p key={key}>
                        <label>
                          <input
                            type="checkbox"
                            checked={value}
                            onChange={() => handleFlagChange(key)}
                          />
                          <span>{flagLabels[key]}</span>
                        </label>
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div id="download-example">
            <a href={`/files/ejemplo-${onboardingType}.csv`} download>
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                id="download-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
              Descargar template CSV
            </a>
          </div>

          <label
            className="pointer"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <div id="box-container">
              <svg
                className="w-8 h-8 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                id="upload-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
              {file ? (
                <p className="text-green-600">
                  Archivo seleccionado: {file.name}
                </p>
              ) : (
                <p className="text-gray-600">
                  Arrastra el archivo CSV o haz clic para seleccionar
                </p>
              )}
            </div>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="hidden"
            />
          </label>
        </div>

        <div className="right-align next-button-div">
          <Button
            variant="yellow-button"
            type="button"
            onClick={handleConfirm}
            disabled={!file}
          >
            Crear onboarding
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;
