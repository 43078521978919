import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import classNames from 'classnames';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Icon from '../../common/airbag-icons/Icon';
import SideModal from '../../common/side-modal/SideModal';
import axiosAuth from '../../../utils/axiosAuth';
import EditCompany from './edit-company/EditCompany';
import CompanyManagers from './managers/CompanyManagers';
import CompanyDrivers from './drivers/CompanyDrivers';
import M from 'materialize-css';
import './AdminCompany.scss';
import Button from '../../common/button/Button';

function AdminCompany(props) {
  const { history, location } = props;
  const companyId = props.match.params.id;
  const [company, setCompany] = useState({});
  const [section, setSection] = useState('managers');
  const [editCompanyOpen, setEditCompanyOpen] = useState(false);

  useEffect(() => {
    fetchCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle tab location
  useEffect(() => {
    const tab = qs.parse(location.search, { ignoreQueryPrefix: true }).tab;
    // If tab has valid value, set it. Else, fallback to info tab
    if (tab === 'managers' || tab === 'drivers') setSection(tab);
    else setSection('managers');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function fetchCompany() {
    setCompany({});

    // Get company info
    axiosAuth
      .get(`/api/companies/${companyId}`)
      .then((res) => {
        const { company } = res.data;
        setCompany(company);
      })
      .catch((err) => {
        alert('Tuvimos un error');
        console.log(err);
      });
  }

  function renderSection() {
    // Render the corresponding section
    if (section === 'managers') {
      return <CompanyManagers company={company} />;
    } else if (section === 'drivers') {
      return <CompanyDrivers company={company} />;
    } else {
      return null;
    }
  }

  function renderPlanType(planType, customPricing) {
    let ret = 'Sin asignar';

    if (planType) {
      if (planType === 'pro') {
        ret = 'Pro';
      }
      if (planType === 'basic') {
        ret = 'Básico';
      }
      if (planType === 'custom') {
        ret = `Personalizada ($${customPricing || 0} x usuario)`;
      }
    }

    return ret;
  }

  function renderPricingModel(pricingModel) {
    let ret = 'Sin asignar';

    if (pricingModel) {
      if (pricingModel === 'activity') {
        ret = 'Por actividad registrada';
      }
      if (pricingModel === 'downloads') {
        ret = 'Por descarga realizada';
      }
    }

    return ret;
  }

  function sanitizeGrowthStatus(status) {
    if (status === 'onboarding') {
      return 'Onboarding';
    }
    if (status === 'negotiation') {
      return 'En negociación';
    }
    if (status === 'churn') {
      return 'Churn';
    }
    if (status === 'active') {
      return 'Activa';
    }
    // Not valid status
    return 'Sin asignar';
  }

  // Loading
  if (!Object.keys(company).length || !company) return <AirbagLoader />;

  return (
    <div id="admin-company">
      <Breadcrumbs
        data={{
          inicio: '/',
          empresas: '/empresas',
          empresa: `/empresa/${companyId}`
        }}
      />
      <h5 className="company-name">{company.name}</h5>
      <span
        id="firestore-id"
        onClick={() => {
          var copySpan = document.getElementById('copy-id-span');
          copySpan.select();

          try {
            var copyResult = document.execCommand('copy');
            if (copyResult)
              M.toast({
                html: 'ID copiado exitosamente',
                classes: 'success-toast'
              });
            else console.log('Oops, unable to copy');
          } catch (err) {
            console.log('Oops, unable to copy');
          }
        }}
      >
        <input id="copy-id-span" defaultValue={company.id} />
      </span>
      <div className="row" id="filters">
        <div className="col s12">
          <Icon
            icon="settings"
            id="edit-icon"
            onClick={() => setEditCompanyOpen(true)}
          />
        </div>
      </div>
      <div className="row" id="company-info">
        <div className="col s12 m6 l4">
          <p className="subtitle">Administradores</p>
          <p className="tableValue">{company.managersCount}</p>
        </div>
        <div className="col s12 m6 l4">
          <p className="subtitle">Conductores</p>
          <p className="tableValue">{company.driversCount}</p>
        </div>
        <div className="col s12 m6 l4">
          <p className="subtitle">Plan</p>
          <p className="tableValue">
            {renderPlanType(company.planType, company.customPricing)}
          </p>
        </div>
        <div className="col s12 m6 l4">
          <p className="subtitle">Modelo de cobro</p>
          <p className="tableValue">
            {renderPricingModel(company.pricingModel)}
          </p>
        </div>
        <div className="col s12 m6 l4">
          <p className="subtitle">Parámetros de telemetría personalizados</p>
          <p className="tableValue">
            {company.hasCustomTelematicsValues ? 'Si' : 'No'}
          </p>
        </div>
        <div className="col s12 m6 l4">
          <p className="subtitle">Límite de velocidad</p>
          <p className="tableValue">
            {company.hasCustomTelematicsValues && company.customTelematicsValues
              ? company.customTelematicsValues.speedLimit + ' km/h'
              : 'Por ley'}
          </p>
        </div>
        <div className="col s12 m6 l4">
          <p className="subtitle">Multiplicador de puntos</p>
          <p className="tableValue">
            {company.coinModifier ? company.coinModifier : 'Sin asignar'}
          </p>
        </div>
        <div className="col s12 m6 l4">
          <p className="subtitle">Estado de growth</p>
          <p className="tableValue">
            {company.growthStatus
              ? sanitizeGrowthStatus(company.growthStatus)
              : 'Sin asignar'}
          </p>
        </div>
      </div>
      <div className="right-align">
        <Button
          type="button"
          variant="dark-button"
          onClick={() =>
            history.push(`/onboarding-companies/company/${companyId}`, {
              company
            })
          }
          id="link-to-onboardings"
        >
          Ver onboardings
        </Button>
      </div>
      <div id="menu-container">
        <span
          onClick={() => {
            setSection('managers');
            history.push(`${location.pathname}`);
          }}
          className={classNames('sec', { active: section === 'managers' })}
        >
          <span className="sec-content">
            <Icon icon="profile" className="sect-icon" />
            Administradores
          </span>
        </span>
        <span
          onClick={() => {
            setSection('drivers');
            history.push(`${location.pathname}?tab=drivers`);
          }}
          className={classNames('sec', {
            active: section === 'drivers'
          })}
        >
          <span className="sec-content">
            <Icon icon="profile" className="sect-icon" />
            Conductores
          </span>
        </span>
      </div>
      {renderSection()}

      {/* Edit company info */}
      <SideModal isOpen={editCompanyOpen} setIsOpen={setEditCompanyOpen}>
        {editCompanyOpen && (
          <EditCompany
            company={company}
            fetchCompany={fetchCompany}
            setIsOpen={setEditCompanyOpen}
          />
        )}
      </SideModal>
    </div>
  );
}

export default withRouter(AdminCompany);
