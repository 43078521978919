import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import axiosAuth from '../../../../utils/axiosAuth';
import HereMap from '../../../common/here-map/HereMap';
import './Realtime.scss';

function Realtime(props) {
  const { companyId, driverId } = props.match.params;
  const [trackingIds, setTrackingIds] = useState([]);
  const [trackingId, setTrackingId] = useState('');
  const [points, setPoints] = useState([]);
  const [center, setCenter] = useState({
    lat: 19.432541,
    lng: -99.133403
  });

  useEffect(() => {
    axiosAuth
      .post('/api/tests/realtime', {
        companyId,
        driverId
      })
      .then((res) => {
        const { activeTrackId, trackingIds } = res.data;

        setTrackingIds(trackingIds);
        setTrackingId(activeTrackId);
        console.log('Active Track Id:', activeTrackId);
      })
      .catch((err) => {
        alert('Tuvimos un error');
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (trackingId) {
      console.log('Querying for', trackingId);
      axiosAuth
        .post('/api/tests/realtime/get-track', {
          trackId: trackingId
        })
        .then((res) => {
          const { points } = res.data;

          setPoints(points);
          console.log(points);

          if (points.length) {
            const lastLocLog = points[0];
            setCenter({
              lat: lastLocLog.lat,
              lng: lastLocLog.lon
            });
          }
        })
        .catch((err) => {
          alert('Tuvimos un error');
          console.log(err);
        });
    }
  }, [trackingId]);

  return (
    <div id="_realtime_">
      <h5>Realtime Tracking</h5>
      <HereMap height={370} zoom={15} center={center} points={points} />
      <div className="input-field">
        <label htmlFor="tracks-ids" className="active">
          Tracking ID
        </label>
        <select
          id="tracks-ids"
          className="browser-default"
          value={trackingId}
          onChange={(e) => setTrackingId(e.target.value)}
        >
          <option value="" disabled>
            Seleccionar..
          </option>
          {trackingIds.map((tId) => (
            <option key={tId} value={tId}>
              {tId}
            </option>
          ))}
        </select>
      </div>
      {points.map((p, idx) => (
        <p
          key={p.id}
          className="point"
          onClick={() => {
            setCenter({ lat: p.lat, lng: p.lon });
          }}
        >
          <span>{idx + 1}.</span>{' '}
          {dayjs(p.created).format('DD [de] MMMM HH:mm:ss')}
        </p>
      ))}
    </div>
  );
}

export default withRouter(Realtime);
