import React from 'react';

function Alert(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M243.61,254.86H40.51a11.81,11.81,0,0,1-10.57-17.1L131.49,34.63a11.81,11.81,0,0,1,21.14,0L254.18,237.76A11.81,11.81,0,0,1,243.61,254.86Z"
        />
        <line
          className="cls-1"
          x1="141.73"
          y1="106.3"
          x2="141.73"
          y2="170.08"
        />
        <circle cx="141.73" cy="198.43" r="7.09" />
      </g>
    </svg>
  );
}

export default Alert;
