export function sanitizeAppStatus(appStatus, isSDKEnabled) {
  // if (typeof appStatus !== 'string' || typeof isSDKEnabled !== 'boolean')
  // 	return 'Inactivo';

  // if (appStatus === 'active' && isSDKEnabled) return 'Activo';
  // if (
  // 	(appStatus === 'active' && !isSDKEnabled) ||
  // 	(appStatus === 'inactive' && isSDKEnabled)
  // )
  // 	return 'Pendiente';
  // if (appStatus === 'inactive' && !isSDKEnabled) return 'Inactivo';

  // First MVP to remove pending
  if (appStatus === 'active') return 'Activo';
  if (appStatus === 'inactive') return 'Inactivo';

  return;
}

export function sanitizeDay(index) {
  var ret;

  switch (index) {
    case '0':
      ret = 'Lunes';
      break;
    case '1':
      ret = 'Martes';
      break;
    case '2':
      ret = 'Miércoles';
      break;
    case '3':
      ret = 'Jueves';
      break;
    case '4':
      ret = 'Viernes';
      break;
    case '5':
      ret = 'Sábado';
      break;
    case '6':
      ret = 'Domingo';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function sanitizeRouteOptimization(type) {
  var ret;

  switch (type) {
    case 'none':
      ret = 'Ninguna';
      break;
    case 'time':
      ret = 'Por tiempo';
      break;
    case 'distance':
      ret = 'Por distancia';
      break;
    default:
      ret = '-';
  }
  return ret;
}

export function phoneNumberFormatter(number) {
  let twoDigitCode = ['81', '55', '33'];
  let ret = '';

  // Remove whitespaces
  number.replace(/ /g, '');

  // Check +52
  if (number.includes('+52')) {
    ret += '+52 ';
    number = number.substring(3);
  }

  // Handle region code: https://www.codigoexactodearea.com/codigo-de-mexico/
  let region = number.substring(0, 2);
  if (twoDigitCode.includes(region)) {
    ret += `(${region}) ${number.substring(2, 6)} ${number.substring(6)}`;
  } else {
    region = number.substring(0, 3);
    ret += `(${region}) ${number.substring(3, 6)} ${number.substring(6)}`;
  }

  return ret;
}
