// Create new cookie
export const setCookie = (cname, cvalue, exseconds) => {
	var d = new Date();
	d.setTime(d.getTime() + exseconds * 1000);
	const expires = d.toUTCString();
	document.cookie = `${cname}=${cvalue};expires=${expires};path=/`;
};

// Delete a cookie
export const deleteCookie = cname => {
	document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
};

// Get a cookie
export const getCookie = cname => {
	const name = cname + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');

	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};
