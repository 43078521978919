import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../../common/airbag-icons/Icon';
import airbag_logo from '../../../assets/logos/airbag_logo.svg';
import sam from '../../../assets/logos/sam.svg';
import './AdminSidenav.scss';

class AdminSidenav extends React.Component {
  state = {
    sidenavOpen: false,
    showCourtain: false
  };

  toggleSidenav = (value) => {
    // Add or remove body scroll
    if (value) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    this.setState({ sidenavOpen: value, showCourtain: value });
  };

  handleCourtainClick = (value) => {
    document.body.classList.remove('no-scroll');
    this.setState({
      sidenavOpen: false,
      showCourtain: false
    });
  };

  logout = () => {
    // Quitamos al user del state
    this.props.unsetUser();
    // Eliminamos la cookie con el token
    this.props.cookies.remove('sabauth', { path: '/' });
    // Redireccionamos
    this.props.history.push('/login');
  };

  render() {
    const { sidenavOpen, showCourtain } = this.state;

    return (
      <div>
        <div
          id="admin-sidenav"
          className={classNames({ 'open-sn': sidenavOpen })}
        >
          <div id="sidenav-wrapper">
            <div id="sidenav-top">
              <div className="center-align">
                <Link to="/">
                  <img src={sam} alt="SAM" id="sidenav-logo" />
                </Link>
              </div>
              <div id="sidenav-profile">
                <p>
                  {this.props.user &&
                    `${this.props.user.name} ${this.props.user.lastName}`}
                </p>
              </div>
            </div>

            {/* Links */}
            <div
              className="sidenav-inner"
              onClick={() => this.toggleSidenav(!sidenavOpen)}
            >
              <ul className="links">
                <li>
                  <NavLink activeClassName="active" exact to="/">
                    <Icon id="home" icon="home" className="icon" />
                    <p>Inicio</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/empresas">
                    <FontAwesomeIcon className="icon" icon="building" />
                    <p>Empresas</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/grupos-empresas">
                    <FontAwesomeIcon className="icon" icon="users" />
                    <p>Grupos de Empresas</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/perfil">
                    <FontAwesomeIcon className="icon" icon="user" />
                    <p>Perfil</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" exact to="/reportes">
                    <FontAwesomeIcon className="icon" icon="book" />
                    <p>Reportes</p>
                  </NavLink>
                </li>
                <li>
                  <span onClick={() => this.logout()}>
                    <FontAwesomeIcon className="icon" icon="lock" />
                    <p>Cerrar Sesión</p>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          id="sidenav-courtain"
          className={classNames({ 'show-nc': showCourtain })}
          onClick={() => this.toggleSidenav(!sidenavOpen)}
        ></div>

        <div className="valign-wrapper" id="admin-navbar">
          <Link to="/">
            <img
              className="right"
              src={airbag_logo}
              alt="Airbag Technologies"
              id="sa-logo"
            />
          </Link>
          <div
            className={classNames('burger', { open: sidenavOpen })}
            id="burger-s"
            onClick={() => this.toggleSidenav(!sidenavOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminSidenav;
