import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import axiosAuth from '../../../../utils/axiosAuth';
import Button from '../../../common/button/Button';
import M from 'materialize-css';

const Step4 = ({ onSubmit, onBack, formData }) => {
  const [isChild, setIsChild] = useState(formData.isChild || false);
  const [parentCompany, setParentCompany] = useState(
    formData.parentCompany || ''
  );
  const [parentCompanies, setParentCompanies] = useState([]);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [matchCompanies, setMatchCompanies] = useState([]);
  const [isParentCompany, setIsParentCompany] = useState(
    formData.isParentCompany || false
  );
  const [canChildrenCreateDrivers, setCanChildrenCreateDrivers] = useState(
    formData.canChildrenCreateDrivers || false
  );
  const [parentCompanyName, setParentCompanyName] = useState('');

  useEffect(() => {
    setIsChild(formData.isChild || false);
    setParentCompany(formData.parentCompany || '');
    setIsParentCompany(formData.isParentCompany || false);
    setCanChildrenCreateDrivers(formData.canChildrenCreateDrivers || false);
  }, [formData]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axiosAuth.get('/api/companies/parentCompanies');
        const parentCompanies = response.data;
        setParentCompanies(parentCompanies);
      } catch (error) {
        M.toast({
          html:
            error.response && error.response.data
              ? error.response.data
              : 'Tuvimos un error para encontrar las empresas',
          classes: 'error-toast'
        });
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      const filteredCompanies = parentCompanies.filter((company) =>
        company.parentName.toLowerCase().includes(debouncedSearch.toLowerCase())
      );
      setMatchCompanies(filteredCompanies);
    } else {
      setMatchCompanies([]);
    }
  }, [debouncedSearch, parentCompanies]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate that parent company was selected if isChild is true
    if (isChild && !parentCompany) {
      M.toast({
        html: 'Debes seleccionar una compañía madre',
        classes: 'error-toast'
      });
      return;
    }

    onSubmit({
      isChild,
      parentCompany,
      isParentCompany,
      canChildrenCreateDrivers
    });
  };

  const handleSelectCompany = (company) => {
    setParentCompany(company.parentId);
    setParentCompanyName(company.parentName);
    setSearch('');
    setMatchCompanies([]);
  };

  return (
    <form onSubmit={handleSubmit} id="step4">
      <h5>4. Grupo de empresas</h5>
      <div className="row">
        {isParentCompany || (!isParentCompany && !isChild) ? (
          <div className="col s12">
            <label>
              <input
                type="checkbox"
                checked={isParentCompany}
                onChange={(e) => setIsParentCompany(e.target.checked)}
              />
              <span>¿Es una compañia padre?</span>
            </label>
          </div>
        ) : null}
        {isParentCompany && (
          <label>
            <input
              type="checkbox"
              checked={canChildrenCreateDrivers}
              onChange={(e) => setCanChildrenCreateDrivers(e.target.checked)}
            />
            <span>¿Las empresas hijas pueden crear conductores?</span>
          </label>
        )}
        {isChild || (!isChild && !isParentCompany) ? (
          <div className="col s12">
            <label>
              <input
                type="checkbox"
                checked={isChild}
                onChange={(e) => setIsChild(e.target.checked)}
              />
              <span>¿Es una compañía hija?</span>
            </label>
          </div>
        ) : null}
        {isChild && (
          <div className="col s12">
            <label>Selecciona la compañía madre</label>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar compañía madre..."
            />
            {matchCompanies.length > 0 && (
              <ul>
                {matchCompanies.map((company) => (
                  <li
                    key={company.parentId}
                    onClick={() => handleSelectCompany(company)}
                  >
                    {company.parentName}
                  </li>
                ))}
              </ul>
            )}
            {parentCompany && (
              <div id="selected-mother">
                Compañía madre seleccionada: {parentCompanyName}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="right-align next-button-div">
        <Button variant="dark-button" type="button" onClick={onBack}>
          Regresar
        </Button>
        <Button variant="yellow-button" type="submit">
          Siguiente
        </Button>
      </div>
    </form>
  );
};

export default Step4;
