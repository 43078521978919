import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';
import { Route, Switch, useLocation } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import moment from 'moment';
import setFontawesomeIcons from '../utils/fontawesome';
import dayjs from 'dayjs';

// Styles
import 'materialize-css/dist/css/materialize.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css-variables/css-variables.scss';
import './App.scss';

// Components
import Login from './auth/login/Login';
import AdminMain from './admin/AdminMain';
import ErrorBoundary from './error-boundary/ErrorBoundary';

// Redux methods and actions
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../actions/user';

/* Moment JS */
moment.updateLocale('es-us', {
  timeZone: 'America/Mexico_City',
  months:
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_'
    ),
  monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sab'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

/* DayJS plugins */
const isoWeek = require('dayjs/plugin/isoWeek');
const updateLocale = require('dayjs/plugin/updateLocale');
const duration = require('dayjs/plugin/duration');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(isoWeek);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(customParseFormat);

// Custom locale
dayjs.locale('es', {
  name: 'es', // name String
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
  months:
    // eslint-disable-next-line max-len
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_'
    ),
  monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_')
});

/* Init Google Analytics */
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

/* Init FontAwesome icons */
setFontawesomeIcons();

function App(props) {
  // Save initial url as previous url to work with on route change
  const [previousUrl, setPreviousUrl] = useState(window.location.pathname);
  const location = useLocation();

  useEffect(() => {
    // Scroll to top on route change only if pathname changes
    if (previousUrl !== location.pathname) window.scrollTo(0, 0);
    // Save url as previous url for next location visit
    setPreviousUrl(location.pathname);
    // Record new url in Google Analytics
    ReactGA.pageview(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ErrorBoundary>
      <Switch>
        <Route path="/login" render={() => <Login {...props} />} />
        <Route path="/" render={() => <AdminMain {...props} />} />
      </Switch>
    </ErrorBoundary>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(
    {
      ...userActions
    },
    dispatch
  );
}

export default withRouter(
  withCookies(connect(mapStateToProps, mapDispachToProps)(App))
);
