import React, { useState, useEffect } from 'react';
import Button from '../../../common/button/Button';

const Step3 = ({ onNext, onBack, formData }) => {
  const [plan, setPlan] = useState(formData.plan || '');
  const [billingMode, setBillingMode] = useState(formData.billingMode || '');
  const [coinModifier, setCoinModifier] = useState(formData.coinModifier || 1);
  const [customPricing, setCustomPricing] = useState(
    formData.customPricing || 0
  );

  useEffect(() => {
    setPlan(formData.plan || '');
    setBillingMode(formData.billingMode || '');
    setCoinModifier(formData.coinModifier || 1);
    setCustomPricing(formData.customPricing || 0);
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext({ plan, billingMode, coinModifier, customPricing });
  };

  return (
    <form onSubmit={handleSubmit} id="step3">
      <h5>3. Plan y modo de Cobro</h5>
      <div className="row">
        <div className="input-field col s12 m6">
          <select
            className="browser-default"
            name="planType"
            value={plan}
            onChange={(e) => setPlan(e.target.value)}
            required
          >
            <option value="" disabled>
              Selecciona un plan
            </option>
            <option value="pro">Pro</option>
            <option value="basic">Básico</option>
            <option value="custom">Personalizado</option>
          </select>
          <label htmlFor="planType" className="active">
            Tipo de plan
          </label>
        </div>
        <div className="input-field col s12 m6">
          <select
            className="browser-default"
            name="country"
            value={billingMode}
            onChange={(e) => setBillingMode(e.target.value)}
            required
          >
            <option value="" disabled>
              Selecciona un modo de cobro
            </option>
            <option value="activity">Por Actividad</option>
            <option value="downloads">Por Descarga</option>
          </select>
          <label htmlFor="country" className="active">
            Modo de cobro
          </label>
        </div>
        <div className="col s12 m6">
          <label className="active" htmlFor="multipl">
            Multiplicador de puntos (hasta 5x)
          </label>
          <input
            name="multipl"
            type="number"
            value={coinModifier}
            onChange={(e) => setCoinModifier(e.target.value)}
            required
            min="1"
            max="5"
            step="0.1"
          />
        </div>
        {plan === 'custom' ? (
          <div className="col s12 m6">
            <label className="active" for="multipl">
              Precio por unidad ($ pesos):
            </label>
            <input
              type="number"
              value={customPricing}
              onChange={(e) => setCustomPricing(e.target.value)}
              required
            />
          </div>
        ) : null}
      </div>

      <div className="right-align next-button-div">
        <Button variant="dark-button" type="button" onClick={onBack}>
          Regresar
        </Button>
        <Button variant="yellow-button" type="submit">
          Siguiente
        </Button>
      </div>
    </form>
  );
};

export default Step3;
