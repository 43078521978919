import React from 'react';

function Settings(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M240.94,127.56H225.57A84.32,84.32,0,0,0,211,92.48L221.91,81.6a14.21,14.21,0,0,0,0-20h0a14.21,14.21,0,0,0-20,0L191,72.43a84.69,84.69,0,0,0-35.08-14.54V42.52a14.21,14.21,0,0,0-14.18-14.17h0a14.21,14.21,0,0,0-14.17,14.17V57.89A84.58,84.58,0,0,0,92.48,72.43L81.6,61.56a14.2,14.2,0,0,0-20,0h0a14.2,14.2,0,0,0,0,20L72.43,92.48a84.58,84.58,0,0,0-14.54,35.08H42.52a14.21,14.21,0,0,0-14.17,14.17h0a14.21,14.21,0,0,0,14.17,14.18H57.89A84.69,84.69,0,0,0,72.43,191L61.56,201.86a14.21,14.21,0,0,0,0,20h0a14.21,14.21,0,0,0,20,0L92.48,211a84.32,84.32,0,0,0,35.08,14.54v15.37a14.22,14.22,0,0,0,14.17,14.18h0a14.22,14.22,0,0,0,14.18-14.18V225.57A84.43,84.43,0,0,0,191,211l10.87,10.88a14.23,14.23,0,0,0,20,0h0a14.23,14.23,0,0,0,0-20L211,191a84.43,84.43,0,0,0,14.54-35.08h15.37a14.22,14.22,0,0,0,14.18-14.18h0A14.22,14.22,0,0,0,240.94,127.56Z"
        />
        <circle className="cls-1" cx="141.73" cy="141.73" r="49.61" />
      </g>
    </svg>
  );
}

export default Settings;
