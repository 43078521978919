import React from 'react';

function Chat(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M201.79,28.35H81.67A53.32,53.32,0,0,0,28.35,81.67V145.1a53.35,53.35,0,0,0,42.52,52.23v57.79l56.69-56.69h74.23a53.33,53.33,0,0,0,53.33-53.33V81.67A53.33,53.33,0,0,0,201.79,28.35Z"
        />
      </g>
    </svg>
  );
}

export default Chat;
