import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './AdminHome.scss';

function AdminHome(props) {
  const { user } = props;

  return (
    <div id="_admin-home_">
      <div className="row no-margin">
        <div className="col s12">
          <h5 id="admin-title">
            Hola, {user.name} {user.lastName}
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m4 l3 box-wrapp">
          <Link to="/empresas">
            <div id="box">
              <FontAwesomeIcon icon="building" />
              <p>Empresas</p>
            </div>
          </Link>
        </div>
        {/* <div className="col s12 m4 l3 box-wrapp">
          <Link to="/grupos-empresas">
            <div id="box">
              <FontAwesomeIcon icon="users" />
              <p>Grupos de empresas</p>
            </div>
          </Link>
        </div> */}
        <div className="col s12 m4 l3 box-wrapp">
          <Link to="/reportes">
            <div id="box">
              <FontAwesomeIcon icon="book" />
              <p>Reportes</p>
            </div>
          </Link>
        </div>
        <div className="col s12 m4 l3 box-wrapp">
          <Link to="/conductores">
            <div id="box">
              <FontAwesomeIcon icon="user" />
              <p>Buscador de conductores</p>
            </div>
          </Link>
        </div>
        <div className="col s12 m4 l3 box-wrapp">
          <Link to="/notificaciones-masivas">
            <div id="box">
              <FontAwesomeIcon icon="envelope" />
              <p>Notificaciones masivas</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
