import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
    // TODO: Consider to change Sentry error logging here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div id="error-boundary" className="valign-wrapper">
          <div id="error-boundary-content">
            <FontAwesomeIcon icon="bomb" />
            <h3>¡Oops! Algo salió mal.</h3>
            <h5>
              No te preocupes, no fue un problema tuyo. Nuestro equipo de
              desarrollo ya fue notificado de este incidente.
            </h5>
            <a href="https://airbagtech.io/">
              <button className="button dark-button" href="/">
                Ir a la pagina principal
              </button>
            </a>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
