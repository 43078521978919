import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import axiosAuth from '../../../../utils/axiosAuth';
import Button from '../../../common/button/Button';
import LoaderFullscreen from '../../../common/loader-fullscreen/LoaderFullscreen';
import M from 'materialize-css';
import './Wizard.scss';

const OnboardingNewCompany = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    companyName: '',
    country: '',
    type: '',
    plan: '',
    billingMode: '',
    isChild: false,
    parentCompany: '',
    coinModifier: 1,
    customPricing: null,
    isParentCompany: false,
    canChildrenCreateDrivers: false
  });

  const handleNext = (data) => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (data) => {
    const updatedFormData = { ...formData, ...data };
    setIsLoading(true);

    try {
      const body = {
        name: updatedFormData.companyName,
        category: updatedFormData.type,
        country: updatedFormData.country,
        planType: updatedFormData.plan,
        pricingModel: updatedFormData.billingMode,
        growthStatus: 'onboarding',
        coinModifier: updatedFormData.coinModifier
      };

      if (updatedFormData.plan === 'custom') {
        body.customPricing = updatedFormData.customPricing;
      }

      if (updatedFormData.isChild) {
        body.parentCompanyId = updatedFormData.parentCompany;
      }

      if (updatedFormData.isParentCompany) {
        body.isParentCompany = updatedFormData.isParentCompany;
        body.canChildrenCreateDrivers =
          updatedFormData.canChildrenCreateDrivers;
      }

      const result = await axiosAuth.post('/api/companies', body);
      setIsLoading(false);
      const companyId = result.data.companyId;
      history.push(`/empresa/${companyId}`);
    } catch (err) {
      console.log(err);
      let msg = 'Tuvimos un error, intenta de nuevo más tarde';
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data === 'string'
      ) {
        msg = err.response.data;
      }
      M.toast({
        html: msg,
        classes: 'error-toast'
      });
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className="" id="onboarding-new-company">
      <div className="right-align" id="cancel-top">
        <Button variant="red-button" type="button" onClick={handleCancel}>
          Cancelar
        </Button>
      </div>
      <div>
        {step === 1 && <Step1 onNext={handleNext} formData={formData} />}
        {step === 2 && (
          <Step2 onNext={handleNext} onBack={handleBack} formData={formData} />
        )}
        {step === 3 && (
          <Step3 onNext={handleNext} onBack={handleBack} formData={formData} />
        )}
        {step === 4 && (
          <Step4
            onSubmit={handleSubmit}
            onBack={handleBack}
            formData={formData}
          />
        )}
      </div>
      {isLoading && <LoaderFullscreen />}
    </div>
  );
};

export default OnboardingNewCompany;
