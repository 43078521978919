import React, { useState } from 'react';
import M from 'materialize-css';
import Button from '../../../common/button/Button';
import axiosAuth from '../../../../utils/axiosAuth';
import './EditCompany.scss';

function EditCompany(props) {
  const { company, fetchCompany, setIsOpen } = props;
  const [loading, setLoading] = useState(false);
  const [hasCustomTelematicsValues, setHasCustomTelematicsValues] = useState(
    company.hasCustomTelematicsValues ? true : false
  );
  const [customSpeedLimit, setCustomSpeedLimit] = useState(
    company.customTelematicsValues
      ? company.customTelematicsValues.speedLimit
      : 80
  );
  const [planType, setPlanType] = useState(company.planType || 'pro');
  const [pricingModel, setPricingModel] = useState(
    company.pricingModel || 'activity'
  );
  const [customPricing, setCustomPricing] = useState(
    company.customPricing || 0
  );
  const [coinModifier, setCoinModifier] = useState(company.coinModifier || '');
  const [growthStatus, setGrowthStatus] = useState(company.growthStatus || '');

  function editCompany(e) {
    e.preventDefault();
    setLoading(true);

    axiosAuth
      .post('/api/companies/edit', {
        hasCustomTelematicsValues,
        customSpeedLimit,
        planType,
        pricingModel,
        companyId: company.id,
        customPricing,
        coinModifier,
        growthStatus
      })
      .then((res) => {
        M.toast({
          html: 'Empresa editada con éxito',
          classes: 'success-toast'
        });
        fetchCompany();
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  return (
    <div id="_edit-company_">
      <h5 id="main-t">Editar Empresa</h5>
      <form onSubmit={(e) => editCompany(e)} noValidate>
        <div className="row big-label">
          <div className="col s12">
            <p>General</p>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m6">
            <select
              name="growthStatus"
              id="growthStatus"
              value={growthStatus}
              onChange={(e) => setGrowthStatus(e.target.value)}
            >
              <option disabled value="">
                Sin asignar
              </option>
              <option value="onboarding">Onboarding</option>
              <option value="negotiation">En negociación</option>
              <option value="churn">Churn</option>
              <option value="active">Activa</option>
            </select>
            <label htmlFor="growthStatus" className="active">
              Estado de growth:
            </label>
          </div>
        </div>
        <div className="row big-label">
          <div className="col s12">
            <p>Billing</p>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m6">
            <select
              name="planType"
              id="planType"
              value={planType}
              onChange={(e) => setPlanType(e.target.value)}
            >
              <option value="pro">Pro</option>
              <option value="basic">Básico</option>
              <option value="custom">Personalizado</option>
            </select>
            <label htmlFor="planType" className="active">
              Plan:
            </label>
          </div>
          <div className="input-field col s12 m6">
            <select
              name="pricingModel"
              id="pricingModel"
              value={pricingModel}
              onChange={(e) => setPricingModel(e.target.value)}
            >
              <option value="activity">Por actividad</option>
              <option value="downloads">Por descargas</option>
            </select>
            <label htmlFor="pricingModel" className="active">
              Modelo de cobro:
            </label>
          </div>
          {planType === 'custom' ? (
            <div className="input-field col s12 m6">
              <input
                name="customPricing"
                id="customPricing"
                type="number"
                placeholder="150"
                value={customPricing}
                onChange={(e) => setCustomPricing(e.target.value)}
              />
              <label htmlFor="customPricing" className="active">
                Precio por unidad ($ pesos):
              </label>
            </div>
          ) : null}
        </div>
        <div className="row big-label">
          <div className="col s12">
            <p>Puntos</p>
          </div>
        </div>
        <div className="row big-label">
          <div className="input-field col s12 m6">
            <input
              name="coinModifier"
              id="coinModifier"
              type="number"
              placeholder="1.15"
              value={coinModifier}
              onChange={(e) => setCoinModifier(e.target.value)}
            />
            <label htmlFor="coinModifier" className="active">
              Multiplicador de puntos:
            </label>
          </div>
        </div>
        <div className="row big-label">
          <div className="col s12">
            <p>Telemetría personalizada</p>
          </div>
        </div>
        <div className="row no-margin">
          <div className="col s12">
            <p>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  name="hasCustomTelematicsValues"
                  checked={hasCustomTelematicsValues}
                  value={hasCustomTelematicsValues}
                  onChange={() =>
                    setHasCustomTelematicsValues(!hasCustomTelematicsValues)
                  }
                />
                <span>Habilitar valores personalizados</span>
              </label>
            </p>
          </div>
          <div className="input-field col s12 m6">
            <input
              name="customSpeedLimit"
              type="text"
              disabled={!hasCustomTelematicsValues}
              value={customSpeedLimit}
              onChange={(e) => setCustomSpeedLimit(e.target.value)}
            />
            <label htmlFor="customSpeedLimit" className="active">
              Límite de velocidad:
            </label>
          </div>
        </div>
        <div id="btn-container">
          <Button
            variant="dark-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Editar empresa
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditCompany;
