import React, { useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '../../../common/breadcrumbs/Breadcrumbs';
import Button from '../../../common/button/Button';
import LoaderFullscreen from '../../../common/loader-fullscreen/LoaderFullscreen';
import { useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faCheckCircle,
  faExclamationCircle,
  faSave
} from '@fortawesome/free-solid-svg-icons';
import M from 'materialize-css';
import axiosAuth from '../../../../utils/axiosAuth';
import './index.scss';

const OnboardingDetail = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [editedRows, setEditedRows] = useState({});
  const [editingCell, setEditingCell] = useState({});
  const { companyId, onboardingId } = useParams();
  const [originalRows, setOriginalRows] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [onboardingData, setOnboardingData] = useState({});
  const location = useLocation();
  const formData = location.state?.formData;
  const columns = [
    { field: 'status', label: 'Estado' },
    { field: 'name', label: 'Nombre(s)' },
    { field: 'lastName', label: 'Apellido(s)' },
    { field: 'phone', label: 'Teléfono' },
    { field: 'group', label: 'Grupo' },
    { field: 'coins', label: 'Puntos' },
    { field: 'actions', label: 'Acciones' }
  ];

  const getStatusStyles = (status) => {
    switch (status) {
      case 'Validado':
        return {
          icon: <FontAwesomeIcon icon={faCheckCircle} className="blue-i" />,
          pillClass: 'blue-pill',
          containerClass: 'blue-container',
          tdClass: 'blue-td'
        };
      case 'Error':
        return {
          icon: (
            <FontAwesomeIcon icon={faExclamationCircle} className="red-i" />
          ),
          pillClass: 'red-pill',
          containerClass: 'red-container',
          tdClass: 'red-td'
        };
      case 'Guardado':
        return {
          icon: <FontAwesomeIcon icon={faSave} className="green-i" />,
          pillClass: 'green-pill',
          containerClass: 'green-container',
          tdClass: 'green-td'
        };
      default:
        return {
          icon: null,
          pillClass: 'yellow-pill',
          containerClass: 'yellow-container',
          tdClass: 'yellow-td'
        };
    }
  };

  const statusOptions = [
    { value: 'all', label: 'Todos', count: drivers?.length },
    {
      value: 'Validado',
      label: 'Validados',
      count: drivers?.filter((d) => d.status === 'Validado')?.length
    },
    {
      value: 'Error',
      label: 'Con errores',
      count: drivers?.filter((d) => d.status === 'Error')?.length
    },
    {
      value: 'Guardado',
      label: 'Guardados',
      count: drivers?.filter((d) => d.status === 'Guardado')?.length
    }
  ];

  const fetchOnboardingData = useCallback(async () => {
    await axiosAuth
      .get(`/api/companies/${companyId}/onboardings/${onboardingId}`)
      .then((response) => {
        setDrivers(response.data.onboarding.users);
        setOriginalRows(
          response.data.onboarding.users.reduce(
            (acc, driver) => ({ ...acc, [driver.id]: driver }),
            {}
          )
        );
        setOnboardingData(response.data.onboarding);
      })
      .catch((error) => {
        console.error('Error fetching onboarding data:', error);
      });
  }, [companyId, onboardingId]);

  useEffect(() => {
    fetchOnboardingData(formData);
  }, [formData, fetchOnboardingData]);

  const handleSaveDrivers = async () => {
    setShowModal(true);

    axiosAuth
      .post(
        `/api/companies/${companyId}/onboarding/${onboardingId}/save-drivers`
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === 'ERROR') {
            M.toast({
              html: 'Tuvimos error al guardar la información de algunos conductores',
              classes: 'error-toast'
            });
          } else {
            M.toast({
              html: 'Onboarding completado exitosamente',
              classes: 'success-toast'
            });
          }
          setDrivers(response.data.users);
          setOriginalRows(
            response.data.users.reduce(
              (acc, driver) => ({ ...acc, [driver.id]: driver }),
              {}
            )
          );
          setOnboardingData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  const isDriverEditable = (driver) => {
    return driver.status !== 'Guardado';
  };

  const handleSendForValidation = async () => {
    try {
      setShowModal(true);
      const response = await axiosAuth.post(
        `/api/companies/${companyId}/onboarding/${onboardingId}/validate-onboarding`,
        {
          drivers
        }
      );
      if (response.status === 200) {
        if (response.data.status === 'ERROR') {
          M.toast({
            html: 'Error en la información, por favor revisa',
            classes: 'error-toast'
          });
        } else {
          M.toast({
            html: 'Información validada exitosamente',
            classes: 'info-toast'
          });
        }
        setDrivers(response.data.users);
        setOriginalRows(
          response.data.users.reduce(
            (acc, driver) => ({ ...acc, [driver.id]: driver }),
            {}
          )
        );
        setOnboardingData(response.data);
        setEditedRows({});
        setShowModal(false);
      }
    } catch (err) {
      console.log(err);
      let msg = 'Tuvimos un error, intenta de nuevo más tarde';
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data === 'string'
      )
        msg = err.response.data;
      M.toast({
        html: msg,
        classes: 'error-toast'
      });
      setShowModal(false);
    }
  };

  const handleCellEdit = (id, field, value) => {
    const updatedDriver = {
      ...drivers.find((driver) => driver.id === id),
      [field]: value
    };

    setDrivers((prev) =>
      prev.map((driver) => (driver.id === id ? updatedDriver : driver))
    );

    setEditedRows((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        ...updatedDriver
      }
    }));
  };

  const handleDoubleClick = (id, field) => {
    const driver = drivers.find((d) => d.id === id);
    if (!isDriverEditable(driver)) return;

    setEditingCell({ id, field });
    if (!originalRows[id]) {
      setOriginalRows((prev) => ({
        ...prev,
        [id]: { ...driver }
      }));
    }
  };

  const handleBlur = () => {
    setEditingCell({});
  };

  const handleKeyDown = (e, id, field) => {
    if (e.key === 'Enter') {
      handleCellEdit(id, field, e.target.value);
      setEditingCell({});
    }
  };

  const handleDiscardChanges = (id) => {
    const originalData = originalRows[id];
    if (originalData) {
      setDrivers((prev) =>
        prev.map((driver) => (driver.id === id ? originalData : driver))
      );

      setEditedRows((prev) => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });

      setEditingCell({});
    }
  };

  const isEditedRow = (id) => {
    const editedRow = editedRows[id];
    const originalRow = originalRows[id];

    if (!editedRow || !originalRow) return false;

    return columns
      .filter((col) => col.field !== 'actions')
      .some((col) => {
        const originalValue = originalRow[col.field];
        const editedValue = editedRow[col.field];
        return editedValue !== undefined && editedValue !== originalValue;
      });
  };

  const renderCell = (driver, field) => {
    const value = driver[field];
    const isEditing =
      editingCell.id === driver.id && editingCell.field === field;
    const editable = isDriverEditable(driver);

    return (
      <div>
        {isEditing ? (
          <input
            value={value}
            onChange={(e) => handleCellEdit(driver.id, field, e.target.value)}
            onBlur={handleBlur}
            onKeyDown={(e) => handleKeyDown(e, driver.id, field)}
            className="edit-input"
            autoFocus
          />
        ) : (
          <span
            onDoubleClick={() => handleDoubleClick(driver.id, field, value)}
            className={`block ${
              editable ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
          >
            {value ||
              (editable ? (
                <span className="edit-span">
                  <FontAwesomeIcon icon={faEdit} />
                  Editar
                </span>
              ) : (
                <span>{value}</span>
              ))}
          </span>
        )}
      </div>
    );
  };

  const filteredDrivers = drivers.filter((driver) =>
    selectedStatus === 'all' ? true : driver.status === selectedStatus
  );

  return (
    <>
      <Breadcrumbs
        data={{
          inicio: '/',
          empresas: '/empresas',
          empresa: `/empresa/${companyId}`,
          onboardings: `/onboarding-companies/company/${companyId}`,
          'Detalle de onboarding': `/onboarding-companies/company/${companyId}/${onboardingId}`
        }}
      />
      <div id="onboarding-detail">
        <div id="top-ctas">
          {Object.keys(editedRows).length ||
          onboardingData.status === 'ERROR' ? (
            <Button variant="blue-button" onClick={handleSendForValidation}>
              <FontAwesomeIcon icon={faCheckCircle} />
              Guardar onboarding
            </Button>
          ) : null}
          {onboardingData.status === 'PENDING' &&
          !Object.keys(editedRows).length ? (
            <Button
              type="button"
              variant="green-button"
              onClick={() => {
                setLoading(true);
                handleSaveDrivers().finally(() => setLoading(false));
              }}
              loading={loading}
            >
              <FontAwesomeIcon icon={faSave} />
              {loading ? 'Guardando conductores' : 'Guardar conductores'}
            </Button>
          ) : null}
        </div>

        <div id="status-indicators">
          {statusOptions.map((option) => {
            const styles = getStatusStyles(option.value);
            return (
              <Button
                key={option.value}
                variant={`dark-button`}
                type="button"
                onClick={() => setSelectedStatus(option.value)}
                className={`
                ${selectedStatus === option.value ? styles.pillClass : ''}
              `}
              >
                {option.value !== 'all' && styles.icon}
                <span className="op-label">{option.label}</span>
                <span className="count-badge">({option.count})</span>
              </Button>
            );
          })}
        </div>

        <div className="airbag-table-container">
          <table className="airbag-table">
            <thead>
              <tr>
                {columns.map((col) => (
                  <th key={col.field} className="col-table-header">
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredDrivers.map((driver) => {
                const styles = getStatusStyles(driver.status);

                return (
                  <tr
                    key={driver.id}
                    className={`
                      ${styles.containerClass}
                      ${isEditedRow(driver.id) ? 'edited' : ''}
                    `}
                  >
                    {/* Status indicator */}
                    <td className={`pill-cont ${styles.tdClass}`}>
                      <span>
                        {styles.icon}
                        {isEditedRow(driver.id) ? 'Editado' : driver.status}
                      </span>
                    </td>

                    <td>{renderCell(driver, 'name')}</td>
                    <td>{renderCell(driver, 'lastName')}</td>
                    <td>{renderCell(driver, 'phone')}</td>
                    <td>{renderCell(driver, 'group')}</td>
                    <td>{renderCell(driver, 'coins')}</td>
                    <td>
                      {isEditedRow(driver.id) && (
                        <Button
                          type="button"
                          variant="red-button"
                          onClick={() => handleDiscardChanges(driver.id)}
                        >
                          Descartar cambios
                        </Button>
                      )}
                      {driver.status === 'Error' &&
                        driver.errors?.length > 0 && (
                          <ul>
                            {driver.errors.map((error, index) => (
                              <li key={index}>- {error}</li>
                            ))}
                          </ul>
                        )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {showModal && <LoaderFullscreen />}
    </>
  );
};

export default OnboardingDetail;
