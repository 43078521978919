import markersCollection from './markersIcons';

// Function to add the drivers markers to the map
function addPoints(points, H, mapUI, mapObj, realtimeGroup) {
  // Declare map group
  const group = new H.map.Group();
  realtimeGroup.current = group;

  for (const point of points) {
    // Validation
    if (!point.lat || !point.lon) continue;

    // Create a point icon from an image URL
    let icon = new H.map.Icon(markersCollection['location_green']);

    // Create a marker using the previously instantiated icon:
    var mapMarker = new H.map.Marker(
      { lat: point.lat, lng: point.lon },
      { icon: icon }
    );

    // Set the text that the marker will show when clicked on
    // mapMarker.setData(`<p>${point.created}</p>`);
    // // Add the event listener to listen clicks on marker
    // mapMarker.addEventListener('tap', (event) => {
    //   const bubble = new H.ui.InfoBubble(
    //     { lat: marker.lat, lng: marker.lng },
    //     {
    //       content: event.target.getData()
    //     }
    //   );
    //   // Add the bubble to the ui
    //   mapUI.current.addBubble(bubble);
    // });

    // Add the marker to the map
    group.addObject(mapMarker);
  }

  // Add the group to the map
  mapObj.current.addObject(group);
}

export default addPoints;
