import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from '../lotties/loading_lineas.json';

function AirbagLoaderLines(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions} height={100} width={100} />;
}

export default AirbagLoaderLines;
