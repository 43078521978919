import React, { useState } from 'react';
import M from 'materialize-css';
import Button from '../../../../common/button/Button';
import axiosAuth from '../../../../../utils/axiosAuth';
import PhoneInput from '../../../../common/phone-input/PhoneInput';
import './AddManager.scss';

function AddManager(props) {
  const { fetchManagers, setIsOpen, companyId } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [authRole, setAuthRole] = useState('admin');
  const [has2FAEnabled, setHas2FAEnabled] = useState(false);

  function addManager(e) {
    e.preventDefault();
    setLoading(true);

    axiosAuth
      .post('/api/managers', {
        companyId,
        name,
        lastName,
        phone,
        email,
        authRole,
        has2FAEnabled
      })
      .then((res) => {
        fetchManagers();
        M.toast({
          html: 'Administrador creado con éxito',
          classes: 'success-toast'
        });
        setIsOpen(false);
      })
      .catch((error) => {
        console.log(error);
        M.toast({
          html: error.response.data,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  return (
    <div id="_add-manager_">
      <h5 id="main-t">Nuevo administrador</h5>
      <form onSubmit={(e) => addManager(e)}>
        {/* INFORMACIÓN GENERAL */}
        <div className="row big-label">
          <div className="col s12">
            <p>Datos generales</p>
          </div>
        </div>
        <div className="row">
          <div className="input-field col s12 m6">
            <input
              name="name"
              type="text"
              value={name}
              placeholder="Juan"
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="nombre" className="active">
              Nombre:
            </label>
          </div>
          <div className="input-field col s12 m6">
            <input
              name="lastName"
              type="text"
              value={lastName}
              placeholder="Torres"
              onChange={(e) => setLastName(e.target.value)}
            />
            <label htmlFor="apellido" className="active">
              Apellido:
            </label>
          </div>
          <div className="col s12 m6">
            <PhoneInput phone={phone} setPhone={setPhone} label="Celular:" />
          </div>
          <div className="input-field col s12 m6">
            <input
              name="email"
              type="email"
              value={email}
              placeholder="juan@ejemplo.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email" className="active">
              Correo electrónico:
            </label>
          </div>
          <div className="input-field col s12 m6">
            <select
              className="browser-default"
              value={authRole}
              name="authRole"
              onChange={(e) => setAuthRole(e.target.value)}
            >
              <option value="admin">Administrador</option>
              <option value="group-limited">Limitado por grupos</option>
            </select>
            <label htmlFor="authRole" className="active">
              Permisos
            </label>
          </div>
          <div className="input-field col s12 m6">
            <p>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={has2FAEnabled}
                  onChange={() => setHas2FAEnabled(!has2FAEnabled)}
                />
                <span>Habilitar 2FA</span>
              </label>
            </p>
          </div>
        </div>
        <div className="center-align">
          <Button
            variant="dark-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Agregar Administrador
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddManager;
