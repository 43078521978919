import React from 'react';

function Routes(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M70.87,28.35H198.43A56.69,56.69,0,0,1,255.12,85h0a56.69,56.69,0,0,1-56.69,56.69H85a56.69,56.69,0,0,0-56.69,56.7h0A56.69,56.69,0,0,0,85,255.12H212.6"
        />
        <ellipse cx="246.44" cy="255.12" rx="6.38" ry="6.23" />
        <ellipse cx="35.43" cy="28.35" rx="6.38" ry="6.23" />
      </g>
    </svg>
  );
}

export default Routes;
