import React from 'react';

function Graph(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <polyline
          className="cls-1"
          points="28.35 255.12 70.87 155.91 113.32 198.34 155.91 113.39 198.43 155.91 255.12 28.35"
        />
      </g>
    </svg>
  );
}

export default Graph;
