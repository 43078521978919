import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import M from 'materialize-css';
import moment from 'moment';
import { sendGAEvent } from '../../../utils/googleAnalytics';
import Button from '../../common/button/Button';
import airbag_logo_negro from '../../../assets/logos/airbag_logo.svg';
import sam from '../../../assets/logos/sam.svg';
import './Login.scss';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Si encontramos un login token, sacamos al usuario
    const token = props.cookies.get('sabauth');
    // Por alguna razón, si es undefined lo guarda como string
    if (token && token !== 'undefined') props.history.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function doLogin(e) {
    e.preventDefault();
    setLoading(true);

    axios
      .post('/api/auth/login', {
        email,
        password
      })
      .then((res) => {
        const { user, token } = res.data;

        // Guardamos el token en una cookie
        props.cookies.set('sabauth', token, {
          path: '/',
          expires: moment().add(7, 'days').toDate()
        });
        // Guardamos al usuario y a la compañía en state
        props.setUser(user);
        // Redireccionamos a su panel
        props.history.push('/');
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        sendGAEvent('Login', 'FAILED', err);
        setLoading(false);
      });
  }

  return (
    <div>
      <div id="nav">
        <Link to="/">
          <img id="logo" src={airbag_logo_negro} alt="Airbag Technologies" />
        </Link>
      </div>
      <div className="valign-wrapper" id="login">
        <div id="box">
          <div className="center-align">
            <img src={sam} alt="SAM" id="sam-logo" />
            <h5>Inicia Sesión</h5>
            {/*<p className="center-align" id="register">
              ¿No tienes cuenta? <Link to="/register">Regístrate</Link>
            </p>*/}
          </div>
          <form onSubmit={doLogin}>
            <div className="row">
              <div className="input-field col s12">
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="email" className="active">
                  Correo electrónico:
                </label>
              </div>
              <div className="input-field col s12">
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="password" className="active">
                  Contraseña:
                </label>
              </div>
              <div className="col s12 center-align">
                <Button
                  variant="dark-button"
                  loading={loading}
                  disabled={loading}
                  type="submit"
                >
                  Aceptar
                </Button>
                {/*<Link to="/forgot-password">
                  <p className="center-align" id="fpl">
                    ¿Olvidaste tu contraseña?
                  </p>
                </Link>*/}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
