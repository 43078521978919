import React, { useState } from 'react';
import Swal from 'sweetalert2';
import M from 'materialize-css';
import Button from '../../../common/button/Button';
import axiosAuth from '../../../../utils/axiosAuth';
import '../MassiveNotifications.scss';

function WhatsAppNotif(props) {
  const [loading, setLoading] = useState(false);
  const [numbersCsvFile, setNumbersCsvFile] = useState('');
  const [waTemplate, setWaTemplate] = useState('lost-driver-1');

  function sendMassiveMessage() {
    if (!numbersCsvFile) {
      M.toast({
        html: 'Selecciona un archivo CSV',
        classes: 'error-toast'
      });
    } else {
      setLoading(true);

      var reader = new FileReader();
      reader.readAsBinaryString(numbersCsvFile);

      reader.onload = function () {
        const result = btoa(reader.result);

        axiosAuth
          .post(`/api/notifications/send-whatsapp`, {
            numbersCsvFileBase64: result,
            waTemplate
          })
          .then((res) => {
            M.toast({
              html: 'Mensaje enviado con éxito',
              classes: 'success-toast'
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            let msg = 'Tuvimos un error, intenta de nuevo más tarde';

            if (err.response && err.response.data) {
              msg = err.response.data;
            }
            M.toast({
              html: msg,
              classes: 'error-toast'
            });
            setLoading(false);
          });
      };
      reader.onerror = function () {
        M.toast({
          html: 'Tuvimos un error al leer el archivo, intenta de nuevo más tarde',
          classes: 'success-toast'
        });
        setLoading(false);
      };
    }
  }

  return (
    <div id="_whatsapp-notifications_">
      <div className="row">
        <div className="col s12">
          <h5>Mensaje por WhatsApp</h5>
        </div>
        <div className="col s12 input-field">
          <input
            name="numbersCsvFile"
            id="numbersCsvFile"
            type="file"
            onChange={(e) => setNumbersCsvFile(e.target.files[0])}
          />
          <label htmlFor="numbersCsvFile" className="active">
            CSV de números
          </label>
        </div>
        <div className="col s12 input-field">
          <select
            name="waTemplate"
            id="waTemplate"
            onChange={(e) => setWaTemplate(e.target.value)}
          >
            <option value="reactivable_driver_1">Mensaje reactivable 1</option>
            <option value="reactivable_driver_2">Mensaje reactivable 2</option>
            <option value="lost-driver-1">Mensaje perdido 1</option>
            <option value="lost-driver-2">Mensaje perdido 2</option>
            <option value="lost_driver_3">Mensaje perdido 3</option>
            <option value="attention_driver_1">Mensaje atención 1</option>
            <option value="inactive_driver_1">Mensaje inactivo 1</option>
            <option value="mensaje1_atencion_">
              Mensaje 1 - Operador en Atención
            </option>
            <option value="mensaje1_nuevos">
              Mensaje 1 - Operadores Nuevos
            </option>
            <option value="mensaje1_reactivados">Mensaje reactivados 1</option>
          </select>
          <label htmlFor="waTemplate" className="active">
            Mensaje:
          </label>
        </div>
        <div className="col s12 input-field">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="button"
            onClick={() => {
              Swal.fire({
                icon: 'question',
                title: '¿Deseas enviar el mensaje de WhatsApp masivo?',
                confirmButtonText:
                  '<span class="dark-color-text">Aceptar</span>',
                confirmButtonColor: '#f6c700',
                showCancelButton: true,
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                  sendMassiveMessage();
                }
              });
            }}
          >
            Enviar mensaje de WhatsApp
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WhatsAppNotif;
