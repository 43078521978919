import React, { useState } from 'react';
import M from 'materialize-css';
import Button from '../../../common/button/Button';
import axiosAuth from '../../../../utils/axiosAuth';
import './AddEmployee.scss';

const INITIAL_DATA = { name: '', lastName: '', email: '' };

function AddEmployee(props) {
	const { companyGroup, setAddEmployeeOpen, fetchEmployees } = props;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(INITIAL_DATA);

	function addEmployee(e) {
		e.preventDefault();
		setLoading(true);

		// Make request
		axiosAuth
			.post('/api/employees', { ...data, companyGroupId: companyGroup.id })
			.then((res) => {
				M.toast({
					html: 'Empleado agregado con éxito',
					classes: 'success-toast'
				});
				fetchEmployees();
				setLoading(false);
				setAddEmployeeOpen(false);
				setData(INITIAL_DATA);
			})
			.catch((err) => {
				console.log(err);
				let msg = 'Tuvimos un error, intenta de nuevo más tarde';
				if (
					err.response &&
					err.response.data &&
					typeof err.response.data === 'string'
				)
					msg = err.response.data;
				M.toast({
					html: msg,
					classes: 'error-toast'
				});
				setLoading(false);
			});
	}

	return (
		<div id="_add-employee_">
			<h5 id="main-t">Nuevo empleado</h5>
			<form onSubmit={addEmployee}>
				{/* INFORMACIÓN GENERAL */}
				<div className="row big-label">
					<div className="col s12">
						<p>Datos del empleado</p>
					</div>
				</div>
				<div className="row">
					<div className="col s12 m6 input-field">
						<label className="active">Nombre(s) *</label>
						<input
							type="text"
							value={data.name}
							onChange={(e) => setData({ ...data, name: e.target.value })}
						/>
					</div>
					<div className="col s12 m6 input-field">
						<label className="active">Apellidos(s) *</label>
						<input
							type="text"
							value={data.lastName}
							onChange={(e) => setData({ ...data, lastName: e.target.value })}
						/>
					</div>
					<div className="col s12 m6 input-field">
						<label className="active">Correo electrónico *</label>
						<input
							type="email"
							value={data.email}
							onChange={(e) => setData({ ...data, email: e.target.value })}
						/>
					</div>
				</div>
				<div className="center-align">
					<Button
						variant="dark-button"
						loading={loading}
						disabled={loading}
						type="submit"
					>
						Agregar empleado
					</Button>
				</div>
			</form>
		</div>
	);
}

export default AddEmployee;
