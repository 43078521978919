import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AddEmployee from './add-employee/AddEmployee';
import MessageCourtain from '../../common/message-courtain/MessageCourtain';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import SideModal from '../../common/side-modal/SideModal';
import axiosAuth from '../../../utils/axiosAuth';
import Icon from '../../common/airbag-icons/Icon';
import './CompanyGroup.scss';

function CompanyGroup(props) {
  const companyGroupId = props.match.params.id;
  const [companyGroup, setCompanyGroup] = useState({});
  const [employees, setEmployees] = useState(null);
  const [assistanceReports, setAssistanceReports] = useState(null);
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);

  useEffect(() => {
    fetchCompanyGroup();
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchCompanyGroup() {
    // Get company groups
    axiosAuth
      .get(`/api/company-groups/${companyGroupId}`)
      .then((res) => {
        const { companyGroup, assistanceReports } = res.data;
        setAssistanceReports(assistanceReports);
        setCompanyGroup(companyGroup);
      })
      .catch((err) => {
        console.log(err);
        alert('Tuvimos un error, intenta de nuevo más tarde');
      });
  }

  function fetchEmployees() {
    setEmployees(null);

    // Get company groups
    axiosAuth
      .get(`/api/employees`, { params: { companyGroupId } })
      .then((res) => {
        const { employees } = res.data;
        setEmployees(employees);
      })
      .catch((err) => {
        console.log(err);
        alert('Tuvimos un error, intenta de nuevo más tarde');
      });
  }

  function renderEmployeesTable() {
    if (!employees)
      return (
        <div className="row box-data">
          <AirbagLoader />
        </div>
      );

    if (!employees.length)
      return (
        <div className="row box-data dark">
          <div className="col s12">
            <p className="center-align">Sin empleados</p>
          </div>
        </div>
      );

    return (
      <div id="employees-table">
        {employees.map((employee, i) => (
          <div className="row box-data employee-row" key={employee.id}>
            <div className="col s5">
              <p>
                <span className="pos-index">{i + 1}.</span>
                {employee.fullName}
              </p>
            </div>
            <div className="col s5">
              <p>{employee.email}</p>
            </div>
            <div className="col s2">
              <p className="right-align"></p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Not found
  if (!companyGroup)
    return (
      <MessageCourtain
        icon="building"
        message="No se encontró al grupo de empresas"
        title="¡Oops!"
        link="/conductores"
      />
    );

  if (!Object.keys(companyGroup).length) return <AirbagLoader />;

  return (
    <div id="_company-group_">
      <Breadcrumbs
        data={{
          inicio: '/',
          grupos: '/grupos-empresas',
          grupo: `/grupo-empresa/${companyGroup.id}`
        }}
      />
      <h5 id="main-title">{companyGroup.name}</h5>
      <div id="filters">
        <Icon icon="more" id="plus" onClick={() => setAddEmployeeOpen(true)} />
      </div>
      <div className="row">
        <div className="col s12">
          <div className="box-container">
            <div className="row box-top-title">
              <div className="col s5">
                <p>Empleado</p>
              </div>
              <div className="col s5">
                <p>Email</p>
              </div>
              <div className="col s2">
                <p></p>
              </div>
            </div>
            {renderEmployeesTable()}
          </div>
        </div>
        {assistanceReports && (
          <div className="col s12">
            <div className="section-card">
              <p className="section-card-title">
                Reportes de asistencia
                <span
                  className={
                    assistanceReports.moduleEnabled
                      ? 'module-active'
                      : 'module-inactive'
                  }
                >
                  {assistanceReports.moduleEnabled ? 'Activo' : 'Inactivo'}
                </span>
              </p>
              <p className="section-card-subtitle">Empresas dadas de alta</p>
              {assistanceReports.childCompanies.map((childCompany, i) => {
                return (
                  <span key={`child-comp-${i}`}>
                    {childCompany.name}
                    {i < assistanceReports.childCompanies.length - 1
                      ? ','
                      : null}
                  </span>
                );
              })}
              <p className="section-card-subtitle">Notificaciones por correo</p>
              {assistanceReports.emailNotifications.map((emailAddress, i) => {
                return (
                  <span key={`email-addr-${i}`}>
                    {emailAddress}
                    {i < assistanceReports.emailNotifications.length - 1
                      ? ','
                      : null}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>

      {/* EDIT EMPLOYEES */}
      <SideModal isOpen={addEmployeeOpen} setIsOpen={setAddEmployeeOpen}>
        <AddEmployee
          companyGroup={companyGroup}
          fetchEmployees={fetchEmployees}
          setAddEmployeeOpen={setAddEmployeeOpen}
        />
      </SideModal>
    </div>
  );
}

export default withRouter(CompanyGroup);
