import React from 'react';

function Gas(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <g>
          <rect
            className="cls-1"
            x="37.35"
            y="28.35"
            width="139.59"
            height="226.77"
          />
          <rect
            className="cls-1"
            x="68.37"
            y="56.69"
            width="77.55"
            height="56.69"
          />
          <path
            className="cls-1"
            d="M184.08,184.25h31V212.6c0,7.83,6.94,14.17,15.51,14.17h0c8.57,0,15.51-6.34,15.51-14.17V85l-31-28.35"
          />
        </g>
      </g>
    </svg>
  );
}

export default Gas;
