import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import M from 'materialize-css';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import Icon from '../../common/airbag-icons/Icon';
import Stat from '../../common/stat/Stat';
import axiosAuth from '../../../utils/axiosAuth';
import './AdminCompanies.scss';

function AdminCompanies(props) {
  const [companies, setCompanies] = useState(null);
  const mexCompanies = useRef(null);
  const colCompanies = useRef(null);
  const usaCompanies = useRef(null);
  const criCompanies = useRef(null);
  const [currCountry, setCurrCountry] = useState('mex');
  const [growthStatus, setGrowthStatus] = useState('all');
  const history = useHistory();

  useEffect(() => {
    // Get companies
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get complete array of companies from current country
    let allCompanies = [];
    if (currCountry === 'mex') {
      allCompanies = mexCompanies.current;
    }
    if (currCountry === 'col') {
      allCompanies = colCompanies.current;
    }
    if (currCountry === 'usa') {
      allCompanies = usaCompanies.current;
    }
    if (currCountry === 'cri') {
      allCompanies = criCompanies.current;
    }

    // Handle growth status, default for all
    let newArray = allCompanies;
    if (growthStatus === 'onboarding') {
      newArray = allCompanies.filter((c) => c.growthStatus === 'onboarding');
    }
    if (growthStatus === 'negotiation') {
      newArray = allCompanies.filter((c) => c.growthStatus === 'negotiation');
    }
    if (growthStatus === 'churn') {
      newArray = allCompanies.filter((c) => c.growthStatus === 'churn');
    }
    if (growthStatus === 'active') {
      newArray = allCompanies.filter((c) => c.growthStatus === 'active');
    }

    setCompanies(newArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [growthStatus, currCountry]);

  function fetchCompanies() {
    axiosAuth
      .get('/api/companies')
      .then((res) => {
        const {
          mexCompanies: mexRes,
          colCompanies: colRes,
          usaCompanies: usaRes,
          criCompanies: criRes
        } = res.data;
        setCompanies(mexRes);
        mexCompanies.current = mexRes;
        colCompanies.current = colRes;
        usaCompanies.current = usaRes;
        criCompanies.current = criRes;
      })
      .catch((err) => {
        M.toast({
          html: err.data
            ? err.data
            : 'Tuvimos un error para encontrar las empresas',
          classes: 'error-toast'
        });
      });
  }

  function redirectToCompany(company) {
    axiosAuth
      .post('/api/auth/sign-company-token', { companyId: company.id })
      .then((res) => {
        const { token } = res.data;

        // Handle domain
        let domainCookie = '.airbagtech.io';
        let domainRedirect = 'https://app.airbagtech.io';
        if (process.env.REACT_APP_ENV === 'development') {
          domainCookie = 'localhost';
          domainRedirect = 'http://localhost:3000';
        }
        // Set cookie
        props.cookies.set('aat', token, {
          path: '/',
          domain: domainCookie
        });
        window.open(domainRedirect, '_blank');
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
      });
  }

  function sumCompaniesData(companies, param) {
    if (!companies || !companies.length) return 0;

    return companies
      .map((c) => (c[param] ? c[param] : 0))
      .reduce((prev, next) => prev + next);
  }

  const handleNavigateToNewCompany = () => {
    history.push(`/onboarding-companies/new`);
  };

  function renderCompaniesTable() {
    // Waiting for companies
    if (!companies)
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );

    // No companies
    if (!companies.length)
      return (
        <div id="no-data">
          <FontAwesomeIcon icon="bomb" id="error-icon" />
          <p>Sin empresas</p>
        </div>
      );

    return (
      <div className="airbag-table-container" id="companies-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th className="center-align">Logistics</th>
              <th>Nombre</th>
              <th className="center-align">Administradores</th>
              <th className="center-align">Conductores</th>
              <th>ID</th>
              <th>Categoría</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, i) => (
              <tr key={company.id}>
                <td className="center-align">
                  <Icon
                    icon="eye"
                    className="company-link"
                    onClick={() => redirectToCompany(company)}
                  />
                </td>
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  <Link to={`/empresa/${company.id}`}>{company.name}</Link>
                </td>
                <td className="center-align">{company.managersCount}</td>
                <td className="center-align">{company.driversCount}</td>
                <td>{company.id}</td>
                <td>{company.category}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div id="admin-companies">
      <Breadcrumbs
        data={{
          inicio: '/',
          empresas: '/empresas'
        }}
      />
      <h5 id="main-title">Empresas</h5>
      <div className="row" id="stats">
        <div className="col s6">
          <Stat
            value={sumCompaniesData(companies, 'managersCount')}
            color="yellow"
          />
          <p>Administradores</p>
        </div>
        <div className="col s6">
          <Stat
            value={sumCompaniesData(companies, 'driversCount')}
            color="yellow"
          />
          <p>Conductores</p>
        </div>
      </div>
      <div id="filters">
        <Icon
          icon="more"
          id="add-icon"
          onClick={() => handleNavigateToNewCompany()}
        />
      </div>
      <div className="row">
        <div className="col s12">
          <div className="custom-menu">
            <div className="menu-container">
              <span
                onClick={() => {
                  setCompanies(mexCompanies.current);
                  setCurrCountry('mex');
                }}
                className={classNames('sec', { active: currCountry === 'mex' })}
              >
                <span className="sec-content">México</span>
              </span>
              <span
                onClick={() => {
                  setCompanies(colCompanies.current);
                  setCurrCountry('col');
                }}
                className={classNames('sec', {
                  active: currCountry === 'col'
                })}
              >
                <span className="sec-content">Colombia</span>
              </span>
              <span
                onClick={() => {
                  setCompanies(usaCompanies.current);
                  setCurrCountry('usa');
                }}
                className={classNames('sec', {
                  active: currCountry === 'usa'
                })}
              >
                <span className="sec-content">EEUU</span>
              </span>
              <span
                onClick={() => {
                  setCompanies(criCompanies.current);
                  setCurrCountry('cri');
                }}
                className={classNames('sec', {
                  active: currCountry === 'cri'
                })}
              >
                <span className="sec-content">Costa Rica</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <div className="custom-menu">
            <div className="menu-container">
              <span
                onClick={() => setGrowthStatus('all')}
                className={classNames('sec', {
                  active: growthStatus === 'all'
                })}
              >
                <span className="sec-content">Todas</span>
              </span>
              <span
                onClick={() => setGrowthStatus('onboarding')}
                className={classNames('sec', {
                  active: growthStatus === 'onboarding'
                })}
              >
                <span className="sec-content">Onboarding</span>
              </span>
              <span
                onClick={() => setGrowthStatus('negotiation')}
                className={classNames('sec', {
                  active: growthStatus === 'negotiation'
                })}
              >
                <span className="sec-content">En negociación</span>
              </span>
              <span
                onClick={() => setGrowthStatus('churn')}
                className={classNames('sec', {
                  active: growthStatus === 'churn'
                })}
              >
                <span className="sec-content">Churn</span>
              </span>
              <span
                onClick={() => setGrowthStatus('active')}
                className={classNames('sec', {
                  active: growthStatus === 'active'
                })}
              >
                <span className="sec-content">Activa</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {renderCompaniesTable()}
    </div>
  );
}

export default AdminCompanies;
