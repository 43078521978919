import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from '../../common/airbag-icons/Icon';
import WhatsAppNotif from './whatsapp/WhatsAppNotif';
import PushNotif from './push/Push';
import './MassiveNotifications.scss';

function MassiveNotifications(props) {
  const [section, setSection] = useState('push');

  // Render the corresponding section
  function renderSection() {
    if (section === 'whatsapp') {
      return <WhatsAppNotif />;
    } else if (section === 'push') {
      return <PushNotif />;
    } else {
      return null;
    }
  }

  return (
    <div id="_massive-notifications_">
      <div id="notif-menu">
        {/* <span
          onClick={() => {
            setSection('whatsapp');
          }}
          className={classNames('sec', { active: section === 'whatsapp' })}
        >
          <span className="sec-content">
            <Icon icon="chat" className="sect-icon" />
            WhatsApp
          </span>
        </span> */}
        <span
          onClick={() => {
            setSection('push');
          }}
          className={classNames('sec', {
            active: section === 'push'
          })}
        >
          <span className="sec-content">
            <Icon icon="chat" className="sect-icon" />
            Push
          </span>
        </span>
      </div>
      {renderSection()}
    </div>
  );
}

export default MassiveNotifications;
