import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import SideModal from '../../common/side-modal/SideModal';
import Icon from '../../common/airbag-icons/Icon';
import { sanitizeAppStatus } from '../../../utils/sanitizers';
import axiosAuth from '../../../utils/axiosAuth';
import roundNumber from '../../../utils/roundNumber';
import Driver from '../company/driver/Driver';
import './Drivers.scss';

function Drivers(props) {
  const [company, setCompany] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [sort, setSort] = useState({ key: 'fullName', order: 'desc' });
  const [driverId, setDriverId] = useState('');
  const [driverInfoOpen, setDriverInfoOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    // We send true so driver's array is emptied after being searched for something
    fetchUsers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    // Wait before firing request for optimization
    const delayDebounceFn = setTimeout(() => {
      const stringNoSpace = searchTerm.replace(/\s+/g, '');
      // If stringNoSpace is number set into setQuery
      if (Number(stringNoSpace)) {
        setQuery(stringNoSpace);
      } else {
        setQuery(searchTerm);
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  function fetchUsers() {
    // Set driver to null for loader
    setDrivers(null);
    // Get location for last seen
    const queryParameters = { getLocation: true };
    // Algolia Search
    if (query) queryParameters.query = query;

    // Get company drivers
    axiosAuth
      .get(`/api/users/search-all-users`, {
        params: queryParameters
      })
      .then((res) => {
        const { drivers: driversRes } = res.data;

        // Set full results to driver's array whit applied filter
        setDrivers(sortArrayByKey(driversRes, sort.key, sort.order));
      })
      .catch((err) => {
        console.log(err);
        alert('Tuvimos un error, intenta de nuevo más tarde');
      });
  }

  function sortArrayByKey(array, key, order) {
    const arrayCopy = [...array];

    if (order === 'desc') {
      arrayCopy.sort(function compare(a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      });
    } else if (order === 'asc') {
      arrayCopy.sort(function compare(a, b) {
        if (b[key] < a[key]) return -1;
        if (b[key] > a[key]) return 1;
        return 0;
      });
    }

    return arrayCopy;
  }

  function renderDriverStatus(driver) {
    const { firstLogin, isResting, appStatus, isSDKEnabled, status } = driver;

    // Discard disabled drivers
    if (status === 'inactive') {
      return <span className="pill red-s">Deshabilitado</span>;
    }

    // First validation, check if user has logged in
    if (firstLogin) {
      return <span className="pill red-s">Sin configurar</span>;
    }

    // Handle users that are resting only for TMS
    if (isResting) {
      return <span className="pill grey-s">En descanso</span>;
    }

    // Default is active or inactive
    return (
      <span
        className={classNames('pill', {
          'green-s': appStatus === 'active',
          'grey-s': appStatus === 'inactive'
        })}
      >
        {sanitizeAppStatus(appStatus, isSDKEnabled)}
      </span>
    );
  }

  function renderDriversTable() {
    // Loader en lo que cargan los conductores
    if (!drivers)
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );

    // Si no hay conductores
    if (!drivers.length)
      return (
        <div id="no-data">
          <Icon icon="error" id="error-icon" />
          <p>Sin conductores</p>
        </div>
      );

    return (
      <div className="airbag-table-container" id="drivers-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'fullName',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'fullName',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Nombre
                  {sort.key === 'fullName' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th>Empresa</th>
              <th>Celular</th>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'coins',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'coins',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Puntos
                  {sort.key === 'coins' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th>Versión App</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver, i) => (
              <tr
                key={driver.userId}
                onClick={() => {
                  setDriverId(driver.userId);
                  setDriverInfoOpen(true);
                  setCompany(driver.company);
                }}
              >
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  {driver.fullName}
                </td>
                <td>{driver.company.name}</td>
                <td>{driver.phone}</td>
                <td>{driver.coins ? roundNumber(driver.coins) : '0'}</td>
                <td>{driver.appInfo ? driver.appInfo.versionApp : '-'}</td>
                <td className="pills-container">
                  {renderDriverStatus(driver)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div id="_company-drivers_">
      <div className="row ">
        <div className="col s12 m4">
          <span className="input-field item-container input-search">
            <label htmlFor="query-input" className="active search-labbel">
              Buscar conductor
            </label>
            <input
              type="text"
              placeholder="Nombre o teléfono del conductor"
              id="query-input"
              value={searchTerm}
              onChange={(e) => {
                // Limit query to 25 chars
                if (e.target.value.length < 26) {
                  setSearchTerm(e.target.value);
                }
              }}
            />
            {query && (
              <FontAwesomeIcon
                icon="times"
                id="query-clear"
                onClick={() => {
                  setQuery('');
                  setSearchTerm('');
                }}
              />
            )}
          </span>
        </div>
      </div>
      {renderDriversTable()}

      {/* Driver information */}
      <SideModal isOpen={driverInfoOpen} setIsOpen={setDriverInfoOpen}>
        {driverInfoOpen && <Driver company={company} driverId={driverId} />}
      </SideModal>
    </div>
  );
}

export default Drivers;
