import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import M from 'materialize-css';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosAuth from '../../../../utils/axiosAuth';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Button from '../../../common/button/Button';
import './BillingReports.scss';

function BillingReports() {
  const [reports, setReports] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableNewReport, setDisableNewReport] = useState(false);
  // Save new report's start date
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    // Fetch usage reports
    fetchBillingReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  function base64toBlob(base64String, sliceSize = 512) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type:
        'vnd.openxmlformats-officedocument' +
        '.spreadsheetml.sheet;charset=utf-8'
    });
    return blob;
  }

  function fetchBillingReports() {
    axiosAuth
      .get('/api/reports/billing-reports')
      .then((res) => {
        const { reports } = res.data;
        setReports(reports);
      })
      .catch((err) => {
        M.toast({
          html: 'Tuvimos un error porfavor intente mas tarde',
          classes: 'error-toast'
        });
        console.log(err);
      });
  }

  function requestDeleteReportBillingClick(reportId) {
    setLoading(true);
    M.toast({
      html: 'Eliminando reporte, este proceso puede tardar varios segundos',
      classes: 'info-toast'
    });

    axiosAuth
      .delete('/api/reports/billing/delete', { params: { reportId } })
      .then((res) => {
        setLoading(false);
        M.toast({
          html: 'Reporte eliminado',
          classes: 'success-toast'
        });
        fetchBillingReports();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
      });
  }

  function requestDownloadReport(reportId) {
    M.toast({
      html: 'Descargando reporte, este proceso puede tardar varios segundos',
      classes: 'info-toast'
    });

    axiosAuth
      .get('/api/reports/billing/download', { params: { reportId } })
      .then((res) => {
        const { base64xlsx, fileName } = res.data;

        // Download report
        downloadReport(base64xlsx, fileName);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
      });
  }

  function downloadReport(base64xlsx, fileName) {
    // Convert base64 to blob for file download
    const blobUrl = URL.createObjectURL(base64toBlob(base64xlsx));
    // Save file with FileSaver package
    FileSaver.saveAs(blobUrl, fileName);
  }

  function renderReportName(report) {
    // Render period dates or default to fileName
    if (report.startDate && report.endDate) {
      return dayjs(report.endDate).format('MMMM [de] YYYY');
    }

    return report.fileName;
  }

  function renderReportsTable() {
    // Show loader
    if (!reports || loading) {
      return (
        <div className="row box-data">
          <AirbagLoader />
        </div>
      );
    }

    // No reports
    if (!reports.length) {
      return (
        <div className="row box-data">
          <div className="col s12">
            <p className="center-align">Sin reportes</p>
          </div>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="reports-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>Periodo evaluado</th>
              <th className="center-align">Descargar</th>
              <th className="center-align">Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, i) => (
              <tr key={report.id} className="report-row">
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  {renderReportName(report)}
                </td>
                <td className="center-align">
                  <FontAwesomeIcon
                    className="icon download-icon"
                    icon="file-download"
                    onClick={() => requestDownloadReport(report.id)}
                  />
                </td>
                <td className="center-align">
                  <FontAwesomeIcon
                    className="icon delete-icon"
                    icon="times"
                    onClick={() => requestDeleteReportBillingClick(report.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function splitDate(startDate) {
    const splitDate = startDate.split('-');
    return splitDate;
  }

  function validateDate(startDate) {
    if (startDate) {
      createBillingReport(startDate);
    } else {
      M.toast({
        html: 'Seleccione una fecha',
        classes: 'error-toast'
      });
      setDisableNewReport(false);
    }
  }

  // Open modal
  function renderNewReportModal() {
    if (modalOpen) {
      return (
        <div className="airbag-table-container modalReport" id="newReportForm">
          <div className="row">
            <div className="closeModalContainer">
              <FontAwesomeIcon
                className="icon"
                id="close-modal-button"
                icon="times"
                onClick={() => {
                  setModalOpen();
                }}
              />
            </div>
            <div className="col s10 reportFormContainer">
              <h4>Nuevo reporte</h4>
              <div className="row">
                <div className="col s12 m3">
                  <label>Fecha</label>
                  <input
                    className="inputReport"
                    type="month"
                    defaultValue={dayjs(startDate).format('YYYY-MM-DD')}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      splitDate(e.target.value);
                    }}
                  ></input>
                </div>
                <div className=" col s12 m4 newReportButton">
                  <Button
                    variant="yellow-button"
                    id="add_new_report"
                    onClick={() => validateDate(startDate)}
                    type="button"
                    disabled={disableNewReport}
                    loading={disableNewReport}
                  >
                    Solicitar reporte
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <p id="exp">
                    * El intervalo de tiempo máximo para generar un reporte es
                    de 1 mes
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  // Request new usage report
  function createBillingReport(startDate) {
    const yearMonth = splitDate(startDate);
    setDisableNewReport(true);

    // Make Request
    axiosAuth
      .post('/api/reports/create-report-billing', {
        month: yearMonth[1],
        year: yearMonth[0]
      })
      .then((res) => {
        M.toast({
          html: res.data,
          classes: 'info-toast'
        });
        setDisableNewReport(false);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: err,
          classes: 'error-toast'
        });
        setDisableNewReport(false);
      });
  }

  return (
    <div id="_billing-reports_">
      <div className="row">
        <div className="col s12 add-button-container">
          {!modalOpen && (
            <Button
              variant="yellow-button"
              id="add-report-btn"
              onClick={() => setModalOpen(true)}
              type="button"
            >
              Solicitar reporte
              {/* <Icon icon="more" id="add-driver-icon" /> */}
            </Button>
          )}
        </div>
        <div className="col s12">{renderNewReportModal()}</div>
        <div className="col s12 ">{renderReportsTable()}</div>
      </div>
    </div>
  );
}
export default BillingReports;
