import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MessageCourtain.scss';

function MessageCourtain(props) {
	const { icon, message, title, link } = props;

	function renderIcon() {
		if (icon) {
			return <FontAwesomeIcon icon={icon} />;
		}
		return <FontAwesomeIcon icon="car-crash" />;
	}

	return (
		<div className="_message-courtain_">
			<div className="courtain-content">
				{renderIcon()}
				<h5>{title}</h5>
				<p>{message}</p>
				<Link to={link ? link : '/'}>
					<button className="button dark-button">Regresar</button>
				</Link>
			</div>
		</div>
	);
}

export default MessageCourtain;
