import React from 'react';
import Favorite from './Favorite';
import Alert from './Alert';
import Chat from './Chat';
import Check from './Check';
import Clock from './Clock';
import Close from './Close';
import Crash from './Crash';
import Day from './Day';
import Download from './Download';
import Error from './Error';
import Gas from './Gas';
import Graph from './Graph';
import Home from './Home';
import MapLocation from './MapLocation';
import More from './More';
import Night from './Night';
import OnOff from './OnOff';
import Pin from './Pin';
import Profile from './Profile';
import Ranking from './Ranking';
import Ranking2 from './Ranking2';
import Velocimeter from './Velocimeter';
import Settings from './Settings';
import Search from './Search';
import Routes from './Routes';
import Record from './Record';
import RealtimeLocation from './RealtimeLocation';
import EventRegister from './EventRegister';
import PerformanceReport from './PerformanceReport';
import Eye from './Eye';

function Icon(props) {
  if (props.icon) {
    switch (props.icon) {
      case 'favorite':
        return <Favorite {...props} />;
      case 'alert':
        return <Alert {...props} />;
      case 'chat':
        return <Chat {...props} />;
      case 'check':
        return <Check {...props} />;
      case 'clock':
        return <Clock {...props} />;
      case 'close':
        return <Close {...props} />;
      case 'crash':
        return <Crash {...props} />;
      case 'day':
        return <Day {...props} />;
      case 'download':
        return <Download {...props} />;
      case 'error':
        return <Error {...props} />;
      case 'gas':
        return <Gas {...props} />;
      case 'graph':
        return <Graph {...props} />;
      case 'home':
        return <Home {...props} />;
      case 'mapLocation':
        return <MapLocation {...props} />;
      case 'more':
        return <More {...props} />;
      case 'night':
        return <Night {...props} />;
      case 'onOff':
        return <OnOff {...props} />;
      case 'pin':
        return <Pin {...props} />;
      case 'profile':
        return <Profile {...props} />;
      case 'ranking':
        return <Ranking {...props} />;
      case 'ranking2':
        return <Ranking2 {...props} />;
      case 'velocimeter':
        return <Velocimeter {...props} />;
      case 'settings':
        return <Settings {...props} />;
      case 'search':
        return <Search {...props} />;
      case 'routes':
        return <Routes {...props} />;
      case 'record':
        return <Record {...props} />;
      case 'realtimeLocation':
        return <RealtimeLocation {...props} />;
      case 'eventRegister':
        return <EventRegister {...props} />;
      case 'performanceReport':
        return <PerformanceReport {...props} />;
      case 'eye':
        return <Eye {...props} />;
      default:
        return null;
    }
  }
  return null;
}

export default Icon;
