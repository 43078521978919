import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import M from 'materialize-css';
import Swal from 'sweetalert2';
import classNames from 'classnames';
import roundNumber from '../../../../utils/roundNumber';
import axiosAuth from '../../../../utils/axiosAuth';
import lastSeen from '../../../../utils/lastSeen';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Button from '../../../common/button/Button';
import SideModal from '../../../common/side-modal/SideModal';
import Icon from '../../../common/airbag-icons/Icon';
import EditManager from './edit-manager/EditManager';
import './Manager.scss';

function Manager(props) {
  const { company, managerId, setManagerInfoOpen, fetchManagers } = props;
  const [manager, setManager] = useState({});
  // Save which section is render
  const [section, setSection] = useState('info');
  const [currentCoins, setCurrentCoins] = useState(0);
  // Open modal to modify coins
  const [coins, setCoins] = useState(0);
  // Save coins Adjustments history
  const [coinsAdjustments, setCoinsAdjustments] = useState(null);
  const [updateCoinsModal, setUpdateCoinsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editManagerOpen, setEditManagerOpen] = useState(false);
  // Reason to modify Points
  const [reason, setReason] = useState(null);
  // Description to modify points
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (section === 'info') {
      // Get driver info
      fetchManager();
    } else {
      // Get coin adjustment info
      fetchCoinAdjustment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  function fetchManager() {
    axiosAuth
      .get(`/api/managers`, {
        params: { companyId: company.id, managerId }
      })
      .then((res) => {
        const { manager } = res.data;
        setManager(manager);
        if (!manager.coins) {
          setCoins(0);
          setCurrentCoins(0);
        } else {
          setCurrentCoins(manager.coins);
          setCoins(manager.coins);
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Tuvimos un error, intenta de nuevo más tarde.');
      });
  }

  // Fetch coins-adjustments and admin name
  function fetchCoinAdjustment() {
    setLoading(true);
    axiosAuth
      .get(`/api/managers/coins-adjustments`, {
        params: { companyId: company.id, managerId }
      })
      .then((res) => {
        // Get coinsAdjustments and admin's name
        setCoinsAdjustments(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert('Tuvimos un error, intenta de nuevo más tarde.');
      });
  }

  // Function to update coins
  function updateCoins() {
    setLoading(true);

    // Make request
    axiosAuth
      .post(`/api/managers/update-coins`, {
        companyId: company.id,
        managerId: manager.id,
        coins,
        reason,
        description
      })
      .then((res) => {
        setLoading(false);
        setUpdateCoinsModal(false);
        setReason(null);
        setManager({ ...manager, coins });
        setCurrentCoins(coins);
        fetchCoinAdjustment();
        M.toast({
          html: 'Puntos guardados exitosamente',
          classes: 'success-toast'
        });
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  function resendLoginData() {
    setLoading(true);

    axiosAuth
      .post(`/api/managers/resend-invite`, {
        managerId,
        companyId: company.id
      })
      .then((res) => {
        M.toast({
          html: 'Se ha enviado la invitación de manera exitosa.',
          classes: 'success-toast'
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const msg = error.msg
          ? error.msg
          : 'Tuvimos un error, intenta de nuevo';
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  function deleteManager() {
    setLoading(true);

    axiosAuth
      .post(`/api/managers/delete`, {
        managerId,
        companyId: company.id
      })
      .then((res) => {
        M.toast({
          html: 'Se eliminó al administrador de manera exitosa.',
          classes: 'success-toast'
        });
        setLoading(false);
        setManagerInfoOpen(false);
        fetchManagers();
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  function unblockManager() {
    setLoading(true);

    axiosAuth
      .post(`/api/managers/unblock`, {
        managerId,
        companyId: company.id
      })
      .then((res) => {
        M.toast({
          html: 'Se debloqueó al administrador de manera exitosa',
          classes: 'success-toast'
        });
        setLoading(false);
        fetchManager();
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        )
          msg = err.response.data;
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  function renderLastSeen(lastSeenDate) {
    if (lastSeenDate) {
      // Get hour diff
      const hourDiff = dayjs().diff(dayjs(lastSeenDate), 'h');

      return (
        <span className={`status ${hourDiff > 120 ? 'error' : 'ok'}`}>
          Últ. vez hace {lastSeen(lastSeenDate)}
        </span>
      );
    }
    return '';
  }

  if (!manager || !Object.keys(manager).length) {
    return <AirbagLoader />;
  }

  function renderInfo() {
    return (
      <>
        <div className="row big-label">
          <div className="col s12">
            <p>Información general</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6">
            <label>Email</label>
            <p className="fake-input">{manager.email}</p>
          </div>
          <div className="col s12 m6">
            <label>Teléfono</label>
            <p className="fake-input">{manager.phone}</p>
          </div>
          <div className="col s12 m6">
            <label>2FA Habilitado</label>
            <p className="fake-input">{manager.has2FAEnabled ? 'Si' : 'No'}</p>
          </div>
        </div>
        <div className="row big-label">
          <div className="col s12">
            <p>Sistema</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6">
            <label>Creado el</label>
            <p className="fake-input">
              {dayjs(manager.created).format('DD MMMM, YYYY HH:mm')}
            </p>
          </div>
          <div className="col s12 m6">
            <label>Aceptó la invitación</label>
            <p className="fake-input">
              {manager.firstLogin
                ? dayjs(manager.firstLogin.date).format('DD MMMM, YYYY HH:mm')
                : 'N/A'}
            </p>
          </div>
          <div className="col s12 m6">
            <label>Login más reciente</label>
            <p className="fake-input">
              {manager.latestLogin
                ? dayjs(manager.latestLogin.date).format('DD MMMM, YYYY HH:mm')
                : 'N/A'}
            </p>
          </div>
          <div className="col s12 m6">
            <label>Nivel de permisos</label>
            <p className="fake-input">
              {manager.authRole === 'admin'
                ? 'Administrador'
                : 'Limitado por grupos'}
            </p>
          </div>
        </div>
        <div className="row" id="bottom-buttons">
          <div className="col s12 right-align">
            <Button
              variant="red-button"
              loading={loading}
              disabled={loading}
              type="button"
              onClick={() => {
                Swal.fire({
                  icon: 'warning',
                  title: '¿Deseas eliminar permanentemente al administrador?',
                  text: 'Esta acción no se puede deshacer',
                  showCancelButton: true,
                  confirmButtonText: 'Si, eliminar',
                  cancelButtonText: 'Cancelar'
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteManager();
                  }
                });
              }}
            >
              Eliminar Administrador
            </Button>
            {manager.status === 'blocked' ? (
              <Button
                variant="yellow-button"
                loading={loading}
                disabled={loading}
                type="button"
                onClick={() => {
                  Swal.fire({
                    icon: 'warning',
                    title: '¿Deseas desbloquear la cuenta del administrador?',
                    showCancelButton: true,
                    confirmButtonText: 'Si, desbloquear',
                    cancelButtonText: 'Cancelar'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      unblockManager();
                    }
                  });
                }}
              >
                Desbloquear cuenta
              </Button>
            ) : null}
          </div>
        </div>
      </>
    );
  }
  // Coins adjustments tab
  function renderCoinsTab() {
    return (
      <>
        <div className="row _driver_">
          <div className="col s12">
            <label>Total de puntos</label>
            <p className="fake-input">{currentCoins}</p>
          </div>
          <div className="col s12 ">
            {!updateCoinsModal && (
              <Button
                variant="yellow-button"
                type="button"
                onClick={() => {
                  setUpdateCoinsModal(true);
                }}
              >
                Modificar Puntos
              </Button>
            )}
          </div>
          <div className="col s12">{renderCoinsModal()}</div>
          <div className="col s12">{renderCoinsTable()}</div>
        </div>
      </>
    );
  }

  function renderCoinsModal() {
    if (updateCoinsModal) {
      return (
        <>
          <div className="closeModalContainer right-align">
            <FontAwesomeIcon
              className="icon"
              id="close-modal-button"
              icon="times"
              onClick={() => {
                setUpdateCoinsModal(false);
              }}
            />
          </div>
          <div className="row">
            <div className="col s12 m6">
              <label htmlFor="coins">Ingresa nueva cantidad</label>
              <input
                className="fake-input"
                name="coins"
                type="number"
                value={coins}
                onChange={(e) => setCoins(e.target.value)}
              />
            </div>
            <div className="col s12 m6">
              <label htmlFor="motive">Motivo</label>
              <select
                name="motive"
                id="motive"
                onChange={(e) => setReason(e.target.value)}
              >
                <option value={null}>Seleccione una opcion</option>
                <option value="bonus">Bono</option>
              </select>
            </div>
            <div className="col s12">
              <div>
                <label htmlFor="motive">Descripcion</label>
                <textarea
                  id="desc-area"
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col s12 right-align">
              <Button
                variant="yellow-button"
                loading={loading}
                disabled={loading}
                type="button"
                onClick={() => {
                  if (!loading) {
                    updateCoins();
                    fetchCoinAdjustment();
                  }
                }}
              >
                Guardar Puntos
              </Button>
            </div>
          </div>
        </>
      );
    }
  }

  function renderSection() {
    if (section === 'info') {
      return renderInfo();
    } else if (section === 'coins') {
      return renderCoinsTab();
    }
  }

  // Render coins Adjustments history
  function renderCoinsTable() {
    // Loader en lo que cargan los conductores
    if (!coinsAdjustments)
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );

    // Si no hay conductores
    if (!coinsAdjustments.length)
      return (
        <div id="no-data">
          <p>Sin movimientos en puntos</p>
        </div>
      );

    // Coin Adjustments history table
    return (
      <div className="airbag-table-container" id="drivers-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span className="pointer">Fecha</span>
              </th>
              <th>
                <span className="pointer">
                  Diferencia <br></br> de puntos
                </span>
              </th>

              <th>
                <span className="pointer">
                  Puntos <br></br> iniciales
                </span>
              </th>
              <th>
                <span className="pointer">
                  Puntos <br></br> finales
                </span>
              </th>
              <th>Motivo</th>
              <th>Administrador</th>
              <th>Descripción</th>
            </tr>
          </thead>
          <tbody>
            {coinsAdjustments.map((coinsAdjustments, i) => (
              <tr key={coinsAdjustments.id}>
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  {dayjs(coinsAdjustments.created).format('DD [de] MMM, YY')}
                </td>
                <td>
                  {coinsAdjustments.action === 'add' ? '+' : null}
                  {roundNumber(coinsAdjustments.diff, 2)}
                </td>
                <td>{roundNumber(coinsAdjustments.oldCoins, 2)}</td>
                <td>{roundNumber(coinsAdjustments.newCoins, 2)}</td>
                <td>
                  {coinsAdjustments.reason ? coinsAdjustments.reason : ''}
                </td>
                <td>{coinsAdjustments.admin.fullName}</td>
                <td>{coinsAdjustments.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div id="_manager_">
      <div className="row">
        <div className="col s12" id="ctas">
          <Icon
            icon="settings"
            id="edit-btn"
            onClick={() => setEditManagerOpen(true)}
          />
        </div>
        <div className="col s12" id="name">
          <h5>
            {manager.name} {manager.lastName}
          </h5>
          <span
            id="firestore-id-manager"
            onClick={() => {
              var copySpan = document.getElementById('copy-manager-id-span');
              copySpan.select();

              try {
                var copyResult = document.execCommand('copy');
                if (copyResult)
                  M.toast({
                    html: 'ID copiado exitosamente',
                    classes: 'success-toast'
                  });
                else console.log('Oops, unable to copy');
              } catch (err) {
                console.log('Oops, unable to copy');
              }
            }}
          >
            <input id="copy-manager-id-span" defaultValue={manager.id} />
          </span>
          <p id="type">Administrador</p>
          <p>
            <span
              className={`status ${manager.emailIsVerified ? 'ok' : 'error'}`}
            >
              {manager.emailIsVerified
                ? 'Ingresó a plataforma'
                : 'Sin ingresar'}
            </span>
            {renderLastSeen(manager.lastActivity)}
            {manager.status === 'blocked' ? (
              <span className="status error">Cuenta bloqueada</span>
            ) : null}
          </p>

          {!manager.emailIsVerified ? (
            <Button
              variant="dark-button"
              loading={loading}
              disabled={loading}
              type="button"
              onClick={() => resendLoginData()}
            >
              Reenviar invitación
            </Button>
          ) : null}
        </div>
        <div id="manager-menu">
          <span
            onClick={() => {
              setSection('info');
            }}
            className={classNames('sec', { active: section === 'info' })}
          >
            <span className="sec-content">
              <Icon icon="profile" className="sect-icon" />
              Información
            </span>
          </span>
          <span
            onClick={() => {
              setSection('coins');
            }}
            className={classNames('sec', {
              active: section === 'coins'
            })}
          >
            <span className="sec-content">
              <Icon icon="ranking2" className="sect-icon" />
              Puntos
            </span>
          </span>
        </div>
      </div>
      {renderSection()}

      {/* Edit manager */}
      <SideModal isOpen={editManagerOpen} setIsOpen={setEditManagerOpen}>
        {editManagerOpen && (
          <EditManager
            fetchManager={fetchManager}
            setIsOpen={setEditManagerOpen}
            manager={manager}
            companyId={company.id}
          />
        )}
      </SideModal>
    </div>
  );
}

export default Manager;
