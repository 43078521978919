import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import SideModal from '../../../common/side-modal/SideModal';
import Icon from '../../../common/airbag-icons/Icon';
import axiosAuth from '../../../../utils/axiosAuth';
import roundNumber from '../../../../utils/roundNumber';
import lastSeen from '../../../../utils/lastSeen';
import Driver from '../driver/Driver';
import './CompanyDrivers.scss';

function CompanyDrivers(props) {
  const { company } = props;
  const lastDriverRef = useRef(null);
  const last = useRef('');
  const keepLooking = useRef(true);
  const loading = useRef(false);
  const [drivers, setDrivers] = useState(null);
  const [sort, setSort] = useState({ key: 'fullName', order: 'desc' });
  const [driverId, setDriverId] = useState(null);
  const [driverInfoOpen, setDriverInfoOpen] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    // We send true so driver's array is emptied after being searched for something
    fetchUsers(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const onScroll = (e) => {
      // If reference to last driver row exists and its in view and fetchUsers keeps returning result
      if (
        drivers &&
        lastDriverRef &&
        lastDriverRef.current &&
        isLastDriverInViewport() &&
        !loading.current &&
        keepLooking.current &&
        !query
      )
        fetchUsers(false);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drivers, lastDriverRef, loading.current, keepLooking, query]);

  function fetchUsers(cleanDriversArray) {
    // Loader inside table
    if (cleanDriversArray) setDrivers(null);
    // Loader inside loop list
    loading.current = true;

    /* Build query parameters */
    // Get location for last seen
    const queryParameters = { getLocation: true };
    // Algolia Search
    if (query) queryParameters.query = query;
    // Pagination
    if (last.current && !cleanDriversArray) queryParameters.last = last.current;

    // Get company drivers
    axiosAuth
      .get(`/api/users/company/${company.id}`, {
        params: queryParameters
      })
      .then((res) => {
        const { drivers: driversRes } = res.data;

        // Save last driver id for pagination
        // before messing with driversRes array
        let lastDriverId = null;
        if (driversRes.length) {
          lastDriverId = driversRes[driversRes.length - 1].id;
        }

        // Process lastSeenSince info for filtering
        const activeDrivers = insertLastSeen(driversRes);

        if (query || cleanDriversArray) {
          // If there is a query, set full results to driver's array
          setDrivers(sortArrayByKey(activeDrivers, sort.key, sort.order));
        } else {
          // Append new drivers response to driver's array
          const driversCopy = drivers
            ? [...drivers, ...activeDrivers]
            : [...activeDrivers];
          // Order by key and apply current filter
          setDrivers(sortArrayByKey(driversCopy, sort.key, sort.order));
        }

        // Stop or resume infinite scrolling
        // The < 50 is hardcoded and must be changed (results per page)
        if (!activeDrivers.length || activeDrivers.length < 50) {
          keepLooking.current = false;
          last.current = '';
        } else {
          keepLooking.current = true;
          // Save last driver's id for pagination
          last.current = lastDriverId;
        }

        // Stop loader
        loading.current = false;
      })
      .catch((err) => {
        console.log(err);
        alert('Tuvimos un error, intenta de nuevo más tarde');
      });
  }

  function isLastDriverInViewport() {
    // Set an offset to trigger action before target element is in view
    const offset = 100;

    const top = lastDriverRef.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  function sortArrayByKey(array, key, order) {
    const arrayCopy = [...array];

    if (order === 'desc') {
      arrayCopy.sort(function compare(a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      });
    } else if (order === 'asc') {
      arrayCopy.sort(function compare(a, b) {
        if (b[key] < a[key]) return -1;
        if (b[key] > a[key]) return 1;
        return 0;
      });
    }

    return arrayCopy;
  }

  function insertLastSeen(rawDrivers) {
    const ret = [];

    rawDrivers.forEach((d) => {
      // Default a negative number for filtering
      d.lastSeenSince = -1;

      // Validate driver's position
      if (d.position && d.position.dateTime) {
        // Make diff negative to always have drivers with no locations last
        d.lastSeenSince = dayjs().diff(dayjs(d.position.dateTime), 'm');
      }

      // Add to array
      ret.push(d);
    });

    return ret;
  }

  function renderDriversTable() {
    // Loader en lo que cargan los conductores
    if (!drivers)
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );

    // Si no hay conductores
    if (!drivers.length)
      return (
        <div id="no-data">
          <Icon icon="error" id="error-icon" />
          <p>Sin conductores</p>
        </div>
      );

    return (
      <div className="airbag-table-container" id="drivers-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'fullName',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'fullName',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Nombre
                  {sort.key === 'fullName' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th>Celular</th>
              <th className="center-align">Última conexión</th>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'coins',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'coins',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Puntos
                  {sort.key === 'coins' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'groupName',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'groupName',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Grupo
                  {sort.key === 'groupName' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th>Versión App</th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver, i) => {
              function renderLastSeenColor(minutes) {
                // Skip resting drivers
                if (driver.isResting) {
                  return 'none';
                }

                // Handle color based on minutes
                if (minutes >= 0 && minutes < 1440) {
                  return 'good';
                }
                if (minutes >= 1440 && minutes < 7200) {
                  return 'regular';
                }
                if (minutes >= 7200) {
                  return 'bad';
                }

                return 'none';
              }

              return (
                <tr
                  key={driver.id}
                  ref={i === drivers.length - 1 ? lastDriverRef : null}
                  onClick={() => {
                    setDriverId(driver.id);
                    setDriverInfoOpen(true);
                  }}
                >
                  <td>
                    <span className="pos-index">{i + 1}.</span>
                    {driver.fullName}
                  </td>
                  <td>{driver.phone}</td>
                  <td
                    className={`last-seen-color ${renderLastSeenColor(
                      driver.lastSeenSince
                    )}`}
                  >
                    {driver.position.dateTime
                      ? lastSeen(driver.position.dateTime)
                      : ''}
                  </td>
                  <td>{driver.coins ? roundNumber(driver.coins) : '0'}</td>
                  <td>{driver.group ? driver.group.name : '-'}</td>
                  <td>{driver.appInfo ? driver.appInfo.versionApp : '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div id="_company-drivers_">
      <div className="row ">
        <div className="col s12 m4">
          <span className="input-field item-container input-search">
            <label htmlFor="query-input" className="active search-labbel">
              Buscar conductor
            </label>
            <input
              type="text"
              placeholder="Nombre o teléfono del conductor"
              id="query-input"
              value={query}
              onChange={(e) => {
                // Limit query to 25 chars
                if (e.target.value.length < 26) {
                  setQuery(e.target.value);
                }
              }}
            />
            {query && (
              <FontAwesomeIcon
                icon="times"
                id="query-clear"
                onClick={() => {
                  setQuery('');
                }}
              />
            )}
          </span>
        </div>
      </div>
      {renderDriversTable()}

      {/* Driver information */}
      <SideModal isOpen={driverInfoOpen} setIsOpen={setDriverInfoOpen}>
        {driverInfoOpen && <Driver company={company} driverId={driverId} />}
      </SideModal>
    </div>
  );
}

export default CompanyDrivers;
