import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import OnboardingHistory from './OnboardingHistory';
import OnboardingModal from './OnboardingModal';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import SideModal from '../../common/side-modal/SideModal';
import Button from '../../common/button/Button';
import LoaderFullscreen from '../../common/loader-fullscreen/LoaderFullscreen';
import axiosAuth from '../../../utils/axiosAuth';
import M from 'materialize-css';
import './CompanyDetail.scss';

const CompanyDetail = () => {
  const { companyId } = useParams();
  const location = useLocation();
  const [company, setCompany] = useState(location.state?.company || null);
  const [onboardings, setOnboardings] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [onboardingType, setOnboardingType] = useState('');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [loadingFullScreen, setLoadingFullScreen] = useState(false);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axiosAuth.get(`/api/companies/${companyId}`);
        setCompany(response.data.company);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching company data:', error);
        M.toast({
          html: error.data
            ? error.data
            : 'Error al cargar la información de la empresa',
          classes: 'error-toast'
        });
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [companyId]);

  useEffect(() => {
    const fetchOnboardings = async () => {
      try {
        setLoading(true);
        const response = await axiosAuth.get(
          `/api/companies/${companyId}/onboardings`
        );
        setLoading(false);
        setOnboardings(response.data.onboardings);
      } catch (error) {
        console.error('Error fetching company data:', error);
        M.toast({
          html: error.data
            ? error.data
            : 'Error al cargar la información de la empresa',
          classes: 'error-toast'
        });
        setLoading(false);
      }
    };

    fetchOnboardings();
  }, [companyId]);

  const handleRegisterDrivers = () => {
    setOnboardingType('conductores');
    setModalOpen(true);
  };

  const handleModalConfirm = async (formData) => {
    try {
      setLoadingFullScreen(true);
      const { file, ...restFormData } = formData;
      const base64File = file
        ? await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(btoa(reader.result));
            reader.readAsBinaryString(file);
          })
        : null;

      const response = await axiosAuth.post(
        `/api/companies/${companyId}/onboarding/save-onboarding`,
        {
          data: {
            ...restFormData,
            file: base64File,
            companyId,
            type: onboardingType
          }
        }
      );
      const onboardingId = response.data.onboardingId;
      M.toast({
        html: 'Onboarding registrado con éxito',
        classes: 'success-toast'
      });
      setLoadingFullScreen(false);
      setModalOpen(false);
      history.push({
        pathname: `/company/${companyId}/onboarding/${onboardingId}`,
        state: { formData }
      });
    } catch (error) {
      setLoadingFullScreen(false);
      console.error('Error creating onboarding:', error);
      M.toast({
        html: error.response?.data || 'Error al registrar el onboarding',
        classes: 'error-toast'
      });
    }
  };

  if (loading) return <AirbagLoader />;

  return (
    <div id="admin-company-ob">
      <Breadcrumbs
        data={{
          inicio: '/',
          empresas: '/empresas',
          empresa: `/empresa/${companyId}`,
          onboardings: `/onboarding-companies/company/${companyId}`
        }}
      />

      <h5 className="company-name">{company?.name}</h5>
      <span
        id="firestore-id"
        onClick={() => {
          var copySpan = document.getElementById('copy-id-span');
          copySpan.select();
          try {
            var copyResult = document.execCommand('copy');
            if (copyResult) {
              M.toast({
                html: 'ID copiado exitosamente',
                classes: 'success-toast'
              });
            }
          } catch (err) {
            console.log('Error al copiar:', err);
          }
        }}
      >
        <input id="copy-id-span" defaultValue={company?.id} />
      </span>
      <div className="row">
        <div className="col s12 right-align">
          <Button
            variant="yellow-button"
            type="button"
            onClick={handleRegisterDrivers}
          >
            Nuevo onboarding
          </Button>
        </div>
      </div>

      {/* Onboarding history */}
      <OnboardingHistory companyId={companyId} onboardings={onboardings} />

      {/* Add new onboarding */}
      <SideModal isOpen={modalOpen} setIsOpen={setModalOpen}>
        {modalOpen && (
          <OnboardingModal
            isOpen={modalOpen}
            onConfirm={handleModalConfirm}
            onboardingType={onboardingType}
          />
        )}
      </SideModal>

      {loadingFullScreen && <LoaderFullscreen />}
    </div>
  );
};

export default CompanyDetail;
