import React, { useEffect, createRef, useRef } from 'react';
import H from '@here/maps-api-for-javascript';
import onResize from 'simple-element-resize-detector';
// import { logsDefinitions } from '../../../utils/logs';
// import { getColors } from '../../../utils/colors';
// import addDriversMarkers from '../../../utils/hereMap/addDriversMarkers';
// import addLastLocationMarker from '../../../utils/hereMap/addLastLocationMarker';
// import displayTrip from '../../../utils/hereMap/displayTrip';
// import handleDailyActivity from '../../../utils/hereMap/handleDailyActivity';
// import fillGaps from '../../../utils/hereMap/fillGaps';
// import animateRoute from '../../../utils/hereMap/animateRoute';
// import calculateRoute from '../../../utils/hereMap/calculateRoute';
// import drawAnalyzedRoute from '../../../utils/hereMap/drawAnalyzedRoute';
// import displayRouteTrips from '../../../utils/hereMap/displayRouteTrips';
import addPoints from '../../../utils/here-map/addPoints';
import './HereMap.scss';

//Component that represents an instance of a HERE Map, functionality of the map will change depending of the props that ared passed through, in order to add a new functionality, a new prop would be required with its corresponding useEffect hook
function HereMap(props) {
  const { width, height, center, zoom, showTraffic, showIncidents, points } =
    props;

  //References for the map, ui, etc.
  const mapRef = createRef();
  const mapObj = useRef(null);
  const mapUI = useRef(null);
  const platformRef = useRef(null);
  const realtimeGroup = useRef(null);

  //Create a map object at component initialization
  useEffect(() => {
    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY
    });
    platformRef.current = platform;
    const layers = platform.createDefaultLayers();
    const map = new H.Map(mapRef.current, layers.vector.normal.map, {
      pixelRatio: window.devicePixelRatio,
      center: center,
      zoom: zoom
    });
    onResize(mapRef.current, () => {
      map.getViewPort().resize();
    });

    //Show the layers based on props
    if (showTraffic) map.addLayer(layers.vector.normal.traffic);
    if (showIncidents) map.addLayer(layers.vector.normal.traffic);

    //Create the basic controls for the map
    const ui = H.ui.UI.createDefault(map, layers, 'es-ES');
    mapUI.current = ui;

    // add the interactive behaviour to the map
    new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

    mapObj.current = map;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Whenever the center prop changes, update the center of the map
  useEffect(() => {
    mapObj.current.setCenter(center);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  //UseEffect to add points
  useEffect(() => {
    if (points && points.length) {
      // This will run on second render. As new realtime tracking is selected, we clean up the map
      if (realtimeGroup && realtimeGroup.current) {
        // Remove trip object
        mapObj.current.removeObject(realtimeGroup.current);
      }

      addPoints(points, H, mapUI, mapObj, realtimeGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  //Representation of the map using refs
  return (
    <div
      style={{
        width: width ? width : '100%',
        height: height ? height : '300px'
      }}
      ref={mapRef}
    ></div>
  );
}

export default HereMap;
