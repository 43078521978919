import dayjs from 'dayjs';

// Get last seen based on date
function lastSeen(date) {
  const currentTime = dayjs();
  const diffTime = currentTime.diff(date, 'seconds');
  const duration = dayjs.duration(diffTime, 'seconds');
  let time = '';

  // Handle years
  if (duration.years()) {
    time += ` ${duration.years()} ${duration.years() > 1 ? 'años' : 'año'}`;
  }

  // Handle months
  if (duration.months()) {
    time += ` ${duration.months()} ${duration.months() > 1 ? 'meses' : 'mes'}`;
    // No need to go more specific
    return time;
  }

  // Handle days
  if (duration.days()) {
    time += ` ${duration.days()} ${duration.days() > 1 ? 'días' : 'día'}`;
    // No need to go more specific
    return time;
  }

  // Handle hours
  if (duration.hours()) time = time + ` ${duration.hours()}h`;

  // Handle minutes
  if (duration.minutes()) time = time + ` ${duration.minutes()}m`;

  // Handle difference of only seconds
  if (!duration.days() && !duration.hours() && !duration.minutes()) {
    if (duration.seconds()) time = ' < 1 m';
  }

  return time;
}

export default lastSeen;
