import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import axiosAuth from '../../../../utils/axiosAuth';
import lastSeen from '../../../../utils/lastSeen';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import SideModal from '../../../common/side-modal/SideModal';
import Manager from '../manager/Manager';
import Icon from '../../../common/airbag-icons/Icon';
import AddManager from './add-manager/AddManager';
import './CompanyManagers.scss';

function CompanyManagers(props) {
  const { company } = props;
  const [managers, setManagers] = useState(null);
  const [managerId, setManagerId] = useState(null);
  const [managerInfoOpen, setManagerInfoOpen] = useState(false);
  const [addManagerOpen, setAddManagerOpen] = useState(false);

  useEffect(() => {
    fetchManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchManagers() {
    // Get managers list
    axiosAuth
      .get(`/api/managers/managers/${company.id}`)
      .then((res) => {
        const { managers } = res.data;
        setManagers(managers);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function renderLastSeen(lastSeenDate) {
    if (lastSeenDate) {
      // Get hour diff
      const hourDiff = dayjs().diff(dayjs(lastSeenDate), 'h');

      return (
        <span className={`status ${hourDiff > 120 ? 'error' : 'ok'}`}>
          {lastSeen(lastSeenDate)}
        </span>
      );
    }
    return '';
  }

  function renderManagersTable() {
    // Loader
    if (!managers)
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );

    // No managers
    if (!managers.length)
      return (
        <div id="no-data">
          <Icon icon="error" id="error-icon" />
          <p>Sin administradores</p>
        </div>
      );

    return (
      <div className="airbag-table-container" id="managers-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Teléfono</th>
              <th>Email</th>
              <th>Puntos</th>
              <th>Permisos</th>
              <th>Ingresó a plataforma</th>
              <th>Última acción</th>
            </tr>
          </thead>
          <tbody>
            {managers.map((manager, i) => (
              <tr
                key={manager.id}
                onClick={() => {
                  setManagerId(manager.id);
                  setManagerInfoOpen(true);
                }}
              >
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  {manager.name} {manager.lastName}
                </td>
                <td>{manager.phone}</td>
                <td>{manager.email}</td>
                <td>{manager.coins}</td>
                <td>
                  {manager.authRole === 'admin'
                    ? 'Administrador'
                    : 'Limitado por grupos'}
                </td>
                <td>
                  <span
                    className={`status ${
                      manager.emailIsVerified ? 'ok' : 'error'
                    }`}
                  >
                    <FontAwesomeIcon icon="circle" />
                    {manager.emailIsVerified ? 'Si' : 'No'}
                  </span>
                </td>
                <td>{renderLastSeen(manager.lastActivity)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div id="_company-managers_">
      <div className="row text-right" id="manager-filters">
        <div className="col s12 right-align">
          <Icon
            icon="more"
            id="add-icon"
            onClick={() => setAddManagerOpen(true)}
          />
        </div>
      </div>
      {renderManagersTable()}

      {/* Manager information */}
      <SideModal isOpen={managerInfoOpen} setIsOpen={setManagerInfoOpen}>
        {managerInfoOpen && (
          <Manager
            company={company}
            managerId={managerId}
            setManagerInfoOpen={setManagerInfoOpen}
            fetchManagers={fetchManagers}
          />
        )}
      </SideModal>

      {/* Add new manager */}
      <SideModal isOpen={addManagerOpen} setIsOpen={setAddManagerOpen}>
        {addManagerOpen && (
          <AddManager
            fetchManagers={fetchManagers}
            setIsOpen={setAddManagerOpen}
            companyId={company.id}
          />
        )}
      </SideModal>
    </div>
  );
}

export default CompanyManagers;
