import React from 'react';

function Crash(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M222.51,222.22l-3.89,23.08a11.18,11.18,0,0,1-12.89,9.17l-12-2a11.18,11.18,0,0,1-9.17-12.89l3.88-23.07"
        />
        <path
          className="cls-1"
          d="M120.19,205l-3.89,23.07a11.21,11.21,0,0,1-12.9,9.18l-12-2a11.19,11.19,0,0,1-9.18-12.9l3.89-23.07"
        />
        <circle className="cls-1" cx="211.92" cy="181.04" r="11.53" />
        <circle className="cls-1" cx="109.84" cy="162.31" r="11.53" />
        <path
          className="cls-1"
          d="M235.93,142.64,236,95.36a27,27,0,0,0-22.5-26.66L144.9,57.14A27,27,0,0,0,116.12,72"
        />
        <path
          className="cls-1"
          d="M126.07,124.12,236,142.64a23,23,0,0,1,18.87,26.53l-9.59,56.89L63.34,195.39l9.59-56.88a23,23,0,0,1,5.19-11.13"
        />
        <path
          className="cls-1"
          d="M96,119.31a23.66,23.66,0,0,1,3.43.32l18.23,3.08"
        />
        <polygon
          className="cls-1"
          points="98.89 113.39 85.16 141.73 71.07 113.02 42.81 127.15 56.94 98.89 28.16 84.58 56.94 70.62 42.81 42.36 71.07 56.49 85.2 28.23 99.33 56.49 127.59 42.36 113.46 70.62 141.68 85.32 113.46 98.89 127.89 127.77 98.89 113.39"
        />
      </g>
    </svg>
  );
}

export default Crash;
