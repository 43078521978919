import React from 'react';

function Error(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path d="M141.73,43.53a6.38,6.38,0,1,1-6.38,6.38,6.17,6.17,0,0,1,6.38-6.38" />
        <path
          className="cls-1"
          d="M28.35,166.54A88.84,88.84,0,0,1,116.93,78h49.61a88.84,88.84,0,0,1,88.58,88.59h0a88.84,88.84,0,0,1-88.58,88.58H116.93a88.84,88.84,0,0,1-88.58-88.58"
        />
        <line
          className="cls-1"
          x1="104.11"
          y1="137.09"
          x2="123.38"
          y2="156.37"
        />
        <line
          className="cls-1"
          x1="123.38"
          y1="137.09"
          x2="104.11"
          y2="156.37"
        />
        <line
          className="cls-1"
          x1="160.08"
          y1="137.09"
          x2="179.36"
          y2="156.37"
        />
        <line
          className="cls-1"
          x1="179.36"
          y1="137.09"
          x2="160.08"
          y2="156.37"
        />
        <line
          className="cls-1"
          x1="99.21"
          y1="191.34"
          x2="184.25"
          y2="191.34"
        />
      </g>
    </svg>
  );
}

export default Error;
