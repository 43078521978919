import React from 'react';

function Home(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <polygon
          className="cls-1"
          points="28.42 255.12 255.26 255.12 255.26 112.2 141.73 28.35 28.42 114.58 28.42 255.12"
        />
        <polyline
          className="cls-1"
          points="177.65 254.76 177.65 170.08 106.03 170.08 106.03 254.76"
        />
      </g>
    </svg>
  );
}

export default Home;
