import axios from 'axios';
import { getCookie } from './cookies';

const axiosAuth = axios.create();

axiosAuth.interceptors.request.use(
	(config) => {
		const token = getCookie('sabauth');
		if (token) config.headers['Authorization'] = 'Bearer ' + token;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default axiosAuth;
