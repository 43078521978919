import React, { useState } from 'react';
import Swal from 'sweetalert2';
import M from 'materialize-css';
import Button from '../../../common/button/Button';
import axiosAuth from '../../../../utils/axiosAuth';
import '../MassiveNotifications.scss';

function PushNotif(props) {
  const [loading, setLoading] = useState(false);
  const [idsCsvFile, setIdsCsvFile] = useState('');
  const [pushTemplate, setPushTemplate] = useState('att_1');

  function sendMassiveMessage() {
    if (!idsCsvFile) {
      M.toast({
        html: 'Selecciona un archivo CSV',
        classes: 'error-toast'
      });
    } else {
      setLoading(true);

      var reader = new FileReader();
      reader.readAsBinaryString(idsCsvFile);

      reader.onload = function () {
        const result = btoa(reader.result);

        axiosAuth
          .post(`/api/notifications/send-push`, {
            idsCsvFileBase64: result,
            pushTemplate
          })
          .then((res) => {
            M.toast({
              html: 'Mensaje enviado con éxito',
              classes: 'success-toast'
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            let msg = 'Tuvimos un error, intenta de nuevo más tarde';

            if (err.response && err.response.data) {
              msg = err.response.data;
            }
            M.toast({
              html: msg,
              classes: 'error-toast'
            });
            setLoading(false);
          });
      };
      reader.onerror = function () {
        M.toast({
          html: 'Tuvimos un error al leer el archivo, intenta de nuevo más tarde',
          classes: 'success-toast'
        });
        setLoading(false);
      };
    }
  }

  return (
    <div id="_push-notifications_">
      <div className="row">
        <div className="col s12">
          <h5>Notificación push</h5>
        </div>
        <div className="col s12 input-field">
          <input
            name="idsCsvFile"
            id="idsCsvFile"
            type="file"
            onChange={(e) => setIdsCsvFile(e.target.files[0])}
          />
          <label htmlFor="idsCsvFile" className="active">
            CSV de IDs
          </label>
        </div>
        <div className="col s12 input-field">
          <select
            name="pushTemplate"
            id="pushTemplate"
            onChange={(e) => setPushTemplate(e.target.value)}
          >
            <option value="att_1">Atención 1</option>
            <option value="att_2">Atención 2</option>
            <option value="risk_1">Riesgo 1</option>
            <option value="risk_2">Riesgo 2</option>
            <option value="star_1">Estrella 1</option>
            <option value="star_2">Estrella 2</option>
            <option value="met_1">Meteorito 1</option>
            <option value="prize_1">Premios 1</option>
            <option value="prize_2">Premios 2</option>
            <option value="prize_3">Premios 3</option>
            <option value="cat_per_1">Catálogo perdido 1</option>
            <option value="cat_react_1">Catálogo reactivable 1</option>
            <option value="10_perd">10 de mayo Perdidos</option>
            <option value="10_react">10 de mayo Reactivables</option>
            <option value="10_recup">10 de mayo Recuperables</option>
            <option value="10_reactivados">10 de mayo Reactivados</option>
            <option value="10_riesgo">10 de mayo Riesgo</option>
            <option value="10_met">10 de mayo Meteorito</option>
            <option value="10_estr">10 de mayo Estrella</option>
            <option value="10_nuevo">10 de mayo Nuevo</option>
            <option value="10_aten">10 de mayo Atención</option>
            <option value="10_ok">10 de mayo Ok</option>
            <option value="all_j_1">Todos los usuarios 1</option>
            <option value="all_j_2">Todos los usuarios 2</option>
            <option value="all_j_3">Todos los usuarios 3</option>
            {/* <option value="daily_bonus_1">Daily Bonus</option> */}
          </select>
          <label htmlFor="pushTemplate" className="active">
            Mensaje:
          </label>
        </div>
        <div className="col s12 input-field">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="button"
            onClick={() => {
              Swal.fire({
                icon: 'question',
                title: '¿Deseas enviar la notificación push masiva?',
                confirmButtonText:
                  '<span class="dark-color-text">Aceptar</span>',
                confirmButtonColor: '#f6c700',
                showCancelButton: true,
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (result.isConfirmed) {
                  sendMassiveMessage();
                }
              });
            }}
          >
            Enviar notificación push
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PushNotif;
