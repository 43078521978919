import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const OnboardingHistory = ({ companyId, onboardings }) => {
  return (
    <div className="mt-6">
      <h6 className="text-xl font-semibold text-gray-700 mb-2">
        Historial de Onboardings
      </h6>
      <div className="airbag-table-container">
        <table className="airbag-table">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-center">Responsable</th>
              <th className="py-2 px-4 border-b text-center">Etapa</th>
              <th className="py-2 px-4 border-b text-center">Tipo</th>
              <th className="py-2 px-4 border-b text-center">Estatus</th>
              <th className="py-2 px-4 border-b text-center">
                Fecha de Creación
              </th>
              <th className="py-2 px-4 border-b text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {onboardings.map((onboarding) => (
              <tr
                key={onboarding.id}
                className="hover:bg-gray-100 cursor-pointer"
              >
                <td className="py-2 px-4 border-b text-center">
                  {onboarding.name}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {onboarding.stage}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {onboarding.type}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {onboarding.status === 'PENDING'
                    ? 'Pendiente'
                    : onboarding.status === 'ERROR'
                    ? 'Con Errores'
                    : onboarding.status === 'SAVED'
                    ? 'Guardado'
                    : 'Pendiente'}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {dayjs(onboarding.created).format(
                    'DD [de] MMM, YYYY [a las] HH:mm'
                  )}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <Link
                    className={'text-blue-500 hover:text-blue-700'}
                    to={`/company/${companyId}/onboarding/${onboarding.id}`}
                  >
                    Ver detalles
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OnboardingHistory;
