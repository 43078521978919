import { withRouter } from 'react-router-dom';
import React, { useState } from 'react';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import GrowthReports from './growthReports/GrowthReports';
import BilingReports from './bilingReports/BilingReports';
import './Reports.scss';

function Reports(props) {
  const [reports, setReports] = useState('grow-reports');

  function renderSection() {
    if (reports === 'grow-reports') {
      return <GrowthReports />;
    } else {
      return <BilingReports />;
    }
  }

  return (
    <div id="_reports_">
      <Breadcrumbs
        data={{
          inicio: '/',
          reportes: '/reportes'
        }}
      />
      <div id="_company-reports_">
        <h5 id="main-title">Reportes</h5>
        <div className="row" id="reports-menu">
          <div
            className={`col s6 m6`}
            onClick={() => {
              setReports('grow-reports');
            }}
          >
            <span className={reports === 'grow-reports' ? 'active' : ''}>
              {/* <Icon icon="performanceReport" id="usage-reports-icon" /> */}
              Growth Reports
            </span>
          </div>
          <div
            className="col s6 m6"
            onClick={() => {
              setReports('billing-reports');
            }}
          >
            <span className={reports === 'billing-reports' ? 'active' : ''}>
              {/* <Icon icon="eventRegister" id="assistance-reports-icon" /> */}
              Billing Reports
            </span>
          </div>
        </div>
        {renderSection()}
      </div>
    </div>
  );
}

export default withRouter(Reports);
