import React, { useState } from 'react';
import M from 'materialize-css';
import axiosAuth from '../../../utils/axiosAuth';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import Button from '../../common/button/Button';
import './AdminProfile.scss';

function AdminProfile(props) {
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState(props.user.name);
	const [lastName, setLastName] = useState(props.user.lastName);
	// const [phone, setPhone] = useState(props.user.phone);
	const [currentPassword, setCurrentPassword] = useState('');
	const [password1, setPassword1] = useState('');
	const [password2, setPassword2] = useState('');

	function editProfile(e) {
		e.preventDefault();
		setLoading(true);

		axiosAuth
			.post('/api/admins/profile', {
				name,
				lastName
			})
			.then((res) => {
				// Update user in props
				props.setUser(res.data.user);
				setLoading(false);
				M.toast({
					html: 'Perfil editado con éxito',
					classes: 'success-toast'
				});
			})
			.catch((err) => {
				console.log(err);
				let msg = 'Tuvimos un error al actualizar tu perfil';
				if (
					err.response &&
					err.response.data &&
					typeof err.response.data === 'string'
				)
					msg = err.response.data;
				setLoading(false);
				M.toast({
					html: msg,
					classes: 'error-toast'
				});
			});
	}

	function editPassword(e) {
		e.preventDefault();
		setLoading(true);

		axiosAuth
			.post('/api/admins/password', {
				currentPassword,
				password1,
				password2
			})
			.then((res) => {
				setLoading(false);
				setCurrentPassword('');
				setPassword1('');
				setPassword2('');
				M.toast({
					html: 'Contraseña actualizada con éxito',
					classes: 'success-toast'
				});
			})
			.catch((err) => {
				console.log(err);
				let msg = 'Tuvimos un error al actualizar tu contraseña';
				if (
					err.response &&
					err.response.data &&
					typeof err.response.data === 'string'
				)
					msg = err.response.data;
				setLoading(false);
				M.toast({
					html: msg,
					classes: 'error-toast'
				});
			});
	}

	return (
		<div id="admin-profile">
			<Breadcrumbs
				data={{
					inicio: '/',
					perfil: '/perfil'
				}}
			/>
			<form id="personal-info-form" onSubmit={editProfile}>
				<div className="row">
					<div className="col s12">
						<h6 className="sub">Perfil</h6>
					</div>
					<div className="input-field col s12 m4">
						<input
							id="name"
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<label htmlFor="nombre" className="active">
							Nombre:
						</label>
					</div>
					<div className="input-field col s12 m4">
						<input
							id="lastName"
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
						<label htmlFor="apellido" className="active">
							Apellido:
						</label>
					</div>
					<div className="input-field col s12 m4">
						<input id="phone" type="text" value={props.user.phone} disabled />
						<label htmlFor="celular" className="active">
							Celular:
						</label>
					</div>
				</div>
				<div className="right-align">
					<Button
						variant="dark-button"
						loading={loading}
						disabled={loading}
						type="submit"
					>
						Editar perfil
					</Button>
				</div>
			</form>
			<div className="divider"></div>
			<form id="password-info-form" onSubmit={editPassword}>
				<div className="row">
					<div className="col s12">
						<h6 className="sub">Contraseña</h6>
					</div>
					<div className="input-field col s12 m4">
						<input
							id="current"
							type="password"
							value={currentPassword}
							onChange={(e) => setCurrentPassword(e.target.value)}
						/>
						<label htmlFor="current" className="active">
							Contraseña actual:
						</label>
					</div>
					<div className="input-field col s12 m4">
						<input
							id="password1"
							type="password"
							value={password1}
							onChange={(e) => setPassword1(e.target.value)}
						/>
						<label htmlFor="new" className="active">
							Nueva contraseña:
						</label>
					</div>
					<div className="input-field col s12 m4">
						<input
							id="password2"
							type="password"
							value={password2}
							onChange={(e) => setPassword2(e.target.value)}
						/>
						<label htmlFor="newRepeat" className="active">
							Confirmar contraseña:
						</label>
					</div>
				</div>
				<div className="right-align">
					<Button
						variant="dark-button"
						loading={loading}
						disabled={loading}
						type="submit"
					>
						Actualizar contraseña
					</Button>
				</div>
			</form>
		</div>
	);
}

export default AdminProfile;
