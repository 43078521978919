import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import axiosAuth from '../../../utils/axiosAuth';
import './CompanyGroups.scss';

function CompanyGroups() {
  const [companyGroups, setCompanyGroups] = useState(null);

  useEffect(() => {
    // Get company groups
    axiosAuth
      .get('/api/company-groups/')
      .then((res) => {
        const { companyGroups } = res.data;
        setCompanyGroups(companyGroups);
      })
      .catch((err) => {
        console.log(err);
        alert('Tuvimos un error, intenta de nuevo más tarde');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderCompanyGroupsTable() {
    // Waiting for company groups
    if (!companyGroups)
      return (
        <div className="row box-data">
          <AirbagLoader />
        </div>
      );

    // No company groups
    if (!companyGroups.length)
      return (
        <div className="row box-data">
          <div className="col s12">
            <p className="center-align">Sin grupos de empresas</p>
          </div>
        </div>
      );

    return (
      <div id="company-groups-table">
        {companyGroups.map((group, i) => (
          <div className="row box-data group-row" key={group.id}>
            <Link to={`/grupo-empresa/${group.id}`}>
              <div className="col s12">
                <p>
                  <span className="pos-index">{i + 1}.</span>
                  {group.name}
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div id="_company-groups_">
      <Breadcrumbs
        data={{
          inicio: '/',
          grupos: '/grupos-empresas'
        }}
      />
      <h5 id="main-title">Grupos de empresas</h5>
      <div className="box-container" id="drivers-table">
        <div className="row box-top-title">
          <div className="col s12">
            <p>Nombre</p>
          </div>
        </div>
        {renderCompanyGroupsTable()}
      </div>
    </div>
  );
}

export default CompanyGroups;
