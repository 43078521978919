import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Breadcrumbs.scss';

function Breadcrumbs(props) {
	return (
		<div className="row _breadcrumbs_">
			<div className="col s12">
				{Object.keys(props.data).map((key, i) => {
					if (Object.keys(props.data).length === i + 1) {
						return (
							<span key={key} className="bold">
								{key}
							</span>
						);
					} else {
						return (
							<React.Fragment key={key}>
								<Link to={props.data[key]}>{key}</Link>
								<FontAwesomeIcon icon="caret-right" className="separator" />
							</React.Fragment>
						);
					}
				})}
			</div>
		</div>
	);
}

export default Breadcrumbs;
