import React from 'react';
import { Route } from 'react-router-dom';
import axiosAuth from '../../utils/axiosAuth';
import AirbagLoaderLines from '../common/airbag-loader/AirbagLoaderLines';
import AdminSidenav from './sidenav/AdminSidenav';
import AdminHome from './home/AdminHome';
import CompanyGroups from './company-groups/CompanyGroups';
import CompanyGroup from './company-group/CompanyGroup';
import AdminCompanies from './companies/AdminCompanies';
import AdminCompany from './company/AdminCompany';
import AdminProfile from './profile/AdminProfile';
import Drivers from './drivers/Drivers';
import Realtime from './company/realtime/Realtime';
import Reports from './reports/Reports';
import MassiveNotifications from './massive-notifications/MassiveNotifications';
import './AdminMain.scss';
import OnboardingNewCompany from './onboarding-companies/new-company/OnboardingNewCompany';
import CompanyDetail from './onboarding-companies/CompanyDetail';
import OnboardingDetail from './onboarding-companies/OnboardingDetail';

class AdminMain extends React.Component {
  componentDidMount() {
    const sabauth = this.props.cookies.get('sabauth');

    // Si no encontramos un login token, sacamos al usuario
    if (!sabauth) this.props.history.push('/login');
    else {
      // Mandamos al servidor
      axiosAuth
        .post('/api/auth/login-with-token', { sabauth })
        .then((res) => {
          const { user } = res.data;

          // Check that user exists and is admin
          if (!user) {
            this.props.cookies.remove('sabauth', { path: '/' });
            this.props.history.push('/login');
          } else {
            // Save in store
            this.props.setUser(user);
          }
        })
        .catch((err) => {
          console.log(err);
          // In production, delete cookie
          if (process.env.REACT_APP_ENV === 'production')
            this.props.cookies.remove('sabauth', { path: '/' });
          this.props.history.push('/login');
        });
    }
  }

  render() {
    const { user } = this.props;

    // Si no hay usuario, mostramos el loader
    if (!Object.keys(user).length)
      return (
        <div className="valign-wrapper" id="loader">
          <AirbagLoaderLines />
        </div>
      );

    return (
      <div id="admin-main">
        <AdminSidenav {...this.props} />
        <div id="admin-content">
          <Route path="/" exact render={() => <AdminHome {...this.props} />} />
          <Route
            path="/empresas"
            render={() => <AdminCompanies {...this.props} />}
          />
          <Route
            exact
            path="/empresa/:id"
            render={() => <AdminCompany {...this.props} />}
          />
          <Route
            exact
            path="/grupos-empresas"
            render={() => <CompanyGroups {...this.props} />}
          />
          <Route
            exact
            path="/grupo-empresa/:id"
            render={() => <CompanyGroup {...this.props} />}
          />
          <Route
            exact
            path="/empresa/:companyId/conductor/:driverId/realtime"
            render={() => <Realtime {...this.props} />}
          />
          <Route
            path="/perfil"
            render={() => <AdminProfile {...this.props} />}
          />
          <Route path="/reportes" render={() => <Reports {...this.props} />} />
          <Route
            path="/conductores"
            render={() => <Drivers {...this.props} />}
          />
          <Route
            path="/notificaciones-masivas"
            render={() => <MassiveNotifications {...this.props} />}
          />
          <Route
            path="/onboarding-companies/new"
            render={() => <OnboardingNewCompany {...this.props} />}
          />
          <Route
            exact
            path="/onboarding-companies/company/:companyId"
            render={() => <CompanyDetail {...this.props} />}
          />
          <Route
            exact
            path="/company/:companyId/onboarding/:onboardingId"
            render={() => <OnboardingDetail {...this.props} />}
          />
        </div>
      </div>
    );
  }
}

export default AdminMain;
