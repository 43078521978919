import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import xss from 'xss';
import Button from '../../../common/button/Button';

const Step1 = ({ formData, onNext }) => {
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    try {
      if (formData.companyName) {
        setCompanyName(formData.companyName);
      }
    } catch (err) {
      setError('Tuvimos un error al cargar el nombre de la empresa');
    }
  }, [formData.companyName]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Validate company name
      if (!companyName || companyName.trim() === '') {
        setError('El nombre de la empresa es requerido');
        return;
      }

      // Sanitize input
      const sanitizedName = xss(companyName.trim());

      // Pass sanitized data to next step
      onNext({
        companyName: sanitizedName,
        created: dayjs().toISOString()
      });
    } catch (err) {
      setError('Tuvimos un error, intenta de nuevo más tarde');
    }
  };

  return (
    <form onSubmit={handleSubmit} id="step1">
      <h5>1. Nombre de la empresa</h5>
      <input
        type="text"
        value={companyName}
        onChange={(e) => {
          setError(null);
          setCompanyName(e.target.value);
        }}
        required
        placeholder="Ingresa el nombre de la empresa"
      />
      {error && <div className="error">{error}</div>}
      <div className="right-align next-button-div">
        <Button variant="yellow-button" type="submit">
          Siguiente
        </Button>
      </div>
    </form>
  );
};

export default Step1;
