import { useState, useEffect } from 'react';
import Button from '../../../common/button/Button';

const Step2 = ({ formData, onNext, onBack }) => {
  const [country, setCountry] = useState('mex'); // Set default to 'mex'
  const [type, setType] = useState('long-haul'); // Set default to 'long-haul'

  useEffect(() => {
    if (formData.country) {
      setCountry(formData.country);
    }
    if (formData.type) {
      setType(formData.type);
    }
  }, [formData.country, formData.type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext({ country, type });
  };

  return (
    <form onSubmit={handleSubmit} id="step2">
      <h5>2. País y categoría de la empresa</h5>
      <div className="row">
        <div className="input-field col s12 m6">
          <select
            className="browser-default"
            value={country}
            name="country"
            onChange={(e) => setCountry(e.target.value)}
            required
          >
            <option disabled>Selecciona una país</option>
            <option value="mex">México</option>
            <option value="col">Colombia</option>
            <option value="per">Perú</option>
            <option value="usa">Estados Unidos</option>
            <option value="cri">Costa Rica</option>
          </select>
          <label htmlFor="country" className="active">
            País
          </label>
        </div>
        <div className="input-field col s12 m6">
          <select
            className="browser-default"
            value={type}
            name="type"
            onChange={(e) => setType(e.target.value)}
            required
          >
            <option disabled>Selecciona una categoría</option>
            <option value="long-haul">Foráneo / Primera milla</option>
            <option value="urban">Urbano / Última milla</option>
          </select>
          <label htmlFor="type" className="active">
            Categoría
          </label>
        </div>
      </div>
      <div className="right-align next-button-div">
        <Button variant="dark-button" type="button" onClick={onBack}>
          Regresar
        </Button>
        <Button variant="yellow-button" type="submit">
          Siguiente
        </Button>
      </div>
    </form>
  );
};

export default Step2;
